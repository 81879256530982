import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { environment } from 'src/environments/environment';
import { ResponseResult } from '../showtime-api/response.structure';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';

@Injectable({
  providedIn: 'root',
})
export class SleepReportService extends ShowTimeApiService {
  constructor(protected http: HttpClient, protected messageService: AlertService) {
    super(messageService);
  }

  getTherapyReport(): Observable<ResponseResult> {
    return this.http
      .get(`${environment.endpoints.apiMyAir}/V1/Patient/SummaryReport`)
      .pipe(this.showError({ disableAlert: true }));
  }
}
