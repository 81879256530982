<form *ngIf="!showNextStepMessage" [formGroup]="form" class="c-form--has-validation" (ngSubmit)="save()" id="passwordForm" autocomplete="on" style="display: block;">

  <div class="js-acc-panel is-active">
    <div class="c-acc-panel c-acc-panel--edit">

      <div class="o-layout">
        <div class="o-layout__item u-2/3@desktop">
          <div class="c-form-field">
            <label class="c-form-field__label" for="oldPassword" id="oldPasswordLabel">
              {{"MY-ACCOUNT/ACCOUNT-DETAILS.CurrentPassword" | translate}}
            </label>
            <div
              class="c-form-field__input c-input--validation"
              [attr.data-form-field-status]="
                ((form.errors?.emailNotConfirmed && form.get('oldPassword').touched) ||
                (form.get('oldPassword').invalid && form.get('oldPassword').touched))
                  ? 'error'
                  : form.get('oldPassword').touched && form.get('oldPassword').valid
                    ? 'completed' : 'incompleted'">
              <input formControlName="oldPassword" type="password" class="c-input c-input--text" id="oldPassword">
            </div>

            <div *ngIf="form.get('oldPassword').invalid && form.get('oldPassword').touched">
              <div *ngIf="form.get('oldPassword').errors?.required" class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}
              </div>
              <div *ngIf="form.get('oldPassword').errors?.invalidAccountPassword" class="c-form-field__error" role="alert" aria-live="assertive">{{"MY-ACCOUNT/ACCOUNT-DETAILS.InvalidAccountPassword" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-2/3@desktop">
          <div class="c-form-field">
            <label class="c-form-field__label" for="password" id="passwordLabel">
              {{"MY-ACCOUNT/ACCOUNT-DETAILS.NewPassword" | translate}}
            </label>

            <div
              class="c-form-field__input c-input--validation"
              [attr.data-form-field-status]="
                ((form.errors?.emailNotConfirmed && form.get('newPassword').touched) ||
                (form.get('newPassword').invalid && form.get('newPassword').touched))
                  ? 'error'
                  : form.get('newPassword').touched && form.get('newPassword').valid
                    ? 'completed' : 'incompleted'">
                <input [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" class="c-input c-input--text" id="password" autofocus="" aria-autocomplete="list">
            </div>

            <div class="o-inline-group-tiny o-inline-group--top">
              <div class="c-input c-input--checkbox is-consent">
                <input id="showPassword" type="checkbox" (change)="showPassword = $event.target.checked"/>
                <label
                  for="showPassword">
                  <span>
                    {{"MY-ACCOUNT/ACCOUNT-DETAILS.Show" | translate}}
                  </span>
                </label>
              </div>
            </div>

            <div *ngIf="form.get('newPassword').invalid && form.get('newPassword').touched">
              <div *ngIf="form.get('newPassword').errors?.pattern" class="c-form-field__error" role="alert" aria-live="assertive">
                {{"ERRORS.passwordFormat" | translate}}</div>
              <div *ngIf="form.get('newPassword').errors?.required" class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}
              </div>
              <div *ngIf="form.get('newPassword').errors?.doesNotMeetPasswordRequirements" class="c-form-field__error" role="alert" aria-live="assertive">{{"MY-ACCOUNT/ACCOUNT-DETAILS.DoesNotMeetPasswordRequirements" | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="o-layout__item u-1/3@desktop">

          <div class="o-callout o-callout--password  u-margin-bottom-large">
            <div class="o-callout__body">
              <span>
                {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordMustMeetRequirements" | translate}}
              </span>

              <ul id="passwordRequirementList" class="list_input-validation">
                <li [class.completed]="!form.get('newPassword').errors?.lengthError">
                  {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordRequirements.Length" | translate}}
                </li>
                <li [class.completed]="!form.get('newPassword').errors?.lowercaseError">
                  {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordRequirements.LowercaseLetter" | translate}}
                </li>
                <li [class.completed]="!form.get('newPassword').errors?.uppercaseError">
                  {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordRequirements.UppercaseLetter" | translate}}
                </li>
                <li [class.completed]="!form.get('newPassword').errors?.numbersError">
                  {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordRequirements.Number" | translate}}
                </li>
                <li [class.completed]="!form.get('newPassword').errors?.specialCharsError">
                  {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordRequirements.SpecialCharacter" | translate}}
                </li>
              </ul>

            </div>
          </div>

        </div>
      </div>


      <div class="c-form-buttons  [ o-layout o-layout--auto ]">
        <div class="o-layout__item">
          <button type="submit" class="c-btn c-btn--primary"
            [disabled]="form.invalid">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Save' | translate}}</button>
        </div>
        <div class="o-layout__item">
          <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Cancel' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="showNextStepMessage" class="c-acc-panel--edit">
  <p class="u-margin-bottom-large">
    {{"MY-ACCOUNT/ACCOUNT-DETAILS.PasswordChanged" | translate}}
  </p>
  <button type="button" class="c-btn c-btn--primary" (click)="nextStepAccepted()">
    {{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Ok' | translate}}</button>
</div>
