import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { DayJSService } from 'src/app/services/dayJS/dayJS.service';
import {
  genderIds,
  GenderId,
  genders,
  GenderName,
} from 'src/app/modules/registration/data/gender.type';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { BaseEditComponent } from 'src/app/modules/my-account/components/base-view-edit-components/base-edit/base-edit.component';
import { TimeZoneData } from 'src/app/modules/registration/data/time-zone.type';
import { ComponentState } from 'src/app/modules/my-account/components/base-view-edit-components/component-state.enum';
import { environment } from 'src/environments/environment';
import { AboutMeDetails } from '../types/about-me.type';

@Component({
  selector: 'mpp-about-me-edit',
  templateUrl: './about-me-edit.component.html',
  styleUrls: ['./about-me-edit.component.scss'],
})
export class AboutMeEditComponent extends BaseEditComponent implements OnInit {
  @Output() aboutMeChanged = new EventEmitter<AboutMeDetails>();
  @Input() aboutMeDetails: AboutMeDetails;
  @Input() allTimezones: readonly TimeZoneData[];
  @Input() isJapanese: boolean;
  form: UntypedFormGroup;
  nameMaxLength = 50;
  minimumAge = 13;
  constructor(
    private fb: UntypedFormBuilder,
    private dayJSService: DayJSService,
    private spinner: SpinnerService,
    private patientService: PatientService,
  ) {
    super();
  }
  async save(): Promise<void> {
    const spinner = this.spinner.show();
    const aboutMeDetails = {
      firstName: (this.form.get('firstName').value as string).trim(),
      lastName: (this.form.get('lastName').value as string).trim(),
      furiganaGivenName: ((this.form.get('furiganaGivenName').value || '') as string).trim(),
      furiganaFamilyName: ((this.form.get('furiganaFamilyName').value || '') as string).trim(),
      dateOfBirth: this.getBirthDayFormatted(),
      gender: this.form.get('gender').value,
      timezoneId: this.form.get('timezoneId').value,
    };

    const [error] = await this.patientService.updateAboutMeDetails(aboutMeDetails).toPromise();
    if (error?.errorInfo) {
      spinner.hide();
      return;
    }
    this.stateChanged.next(ComponentState.View);
    this.aboutMeChanged.next(aboutMeDetails);
    spinner.hide();
  }

  private getBirthDayFormatted(): string {
    return `${this.form.get('birthday.year').value}-${this.dayJSService
      .dayJS(this.form.get('birthday.month').value, 'MMM')
      .format('MM')}-${this.form.get('birthday.day').value.toString().padStart(2, '0')}`;
  }

  ngOnInit(): void {
    this.minimumAge = environment.isEU ? 16 : 13;
    this.form = this.fb.group({
      firstName: [
        this.aboutMeDetails.firstName,
        [Validators.pattern('.*[^ ]+.*'), Validators.required],
      ],
      lastName: [
        this.aboutMeDetails.lastName,
        [Validators.pattern('.*[^ ]+.*'), Validators.required],
      ],
      furiganaGivenName: [
        this.aboutMeDetails.furiganaGivenName,
        this.isJapanese
          ? [Validators.pattern('.*[^ ]+.*'), Validators.required]
          : Validators.pattern('.*[^ ]+.*'),
      ],
      furiganaFamilyName: [
        this.aboutMeDetails.furiganaFamilyName,
        this.isJapanese
          ? [Validators.pattern('.*[^ ]+.*'), Validators.required]
          : Validators.pattern('.*[^ ]+.*'),
      ],
      birthday: this.fb.group(
        {
          day: [
            this.dayJSService.dayJS(this.aboutMeDetails.dateOfBirth).get('date'),
            Validators.required,
          ],
          month: [
            this.dayJSService.dayJS(this.aboutMeDetails.dateOfBirth).format('MMM'),
            Validators.required,
          ],
          year: [
            this.dayJSService.dayJS(this.aboutMeDetails.dateOfBirth).get('year'),
            Validators.required,
          ],
        },
        {
          validators: [
            RegistrationValidators.isBirthdayCorrect(this.dayJSService, this.minimumAge),
            RegistrationValidators.isBirthdaySet,
          ],
        },
      ),
      gender: [this.aboutMeDetails.gender, Validators.required],
      timezoneId: [this.aboutMeDetails.timezoneId, Validators.required],
    });
  }

  public getDataFormFieldStatus(dataFieldName: string): string {
    return this.getFieldStatus(dataFieldName, this.form);
  }
  public getGenderIds(): readonly string[] {
    return genderIds;
  }

  public getGenderName(genderId: GenderId): GenderName {
    return genders[genderId];
  }
}