import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryService } from 'src/app/services/country/country.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { BaseComponent } from '../../base/component/base-component';
import { WelcomeVideoComponent } from '../welcome-video/welcome-video.component';

@Component({
  selector: 'mpp-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent extends BaseComponent implements OnInit {
  slickOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    cssEase: 'ease-out',
    infinite: true,
  };
  showMobileAppsBanner = false;
  showVideoBanner = false;
  showRegistrationBanner = false;

  @ViewChild(WelcomeVideoComponent) welcomeVideoComponent: WelcomeVideoComponent;
  playVideo(): void {
    this.welcomeVideoComponent.playVideo();
  }

  constructor(public analytics: AnalyticsService, public countryService: CountryService, public languageService: LanguageService, public router: Router) {
    super();
  }

  ngOnInit(): void {
    // get and set appStoreLink$ and playStoreLink$
    this.countryService.initializeMobileStoreLinks();
    this.setAppBanner();
    this.showRegistrationBanner = this.countryService.latAmNonRollout();

    this.languageService.selectedLanguageId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setVideoBanner();
      this.enableBannerDots();
    });
  }

  playStoreLinkClicked(): void {
    this.analytics.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analytics.redirectLocationVideoCarousel);
  }

  appStoreLinkClicked(): void {
    this.analytics.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analytics.redirectLocationVideoCarousel);
  }

  setVideoBanner(): void {
    this.showVideoBanner = ["ROW"].includes(
      this.countryService.getSelectedCountry().region,
    );

    if (!this.showVideoBanner) {
      this.showVideoBanner = ['EnglishUk', 'German', 'French'].includes(
        this.languageService.selectedLanguageId,
      );
    }
  }

  setAppBanner(): void {
    if (this.countryService.latAmNonRollout()) {
      this.showMobileAppsBanner = false;
    } else {
      this.showMobileAppsBanner = true;
    }
  }

  enableBannerDots(): void {
    this.slickOptions = {
      ...this.slickOptions,
      dots:
        [this.showRegistrationBanner, this.showMobileAppsBanner, this.showVideoBanner].reduce(
          (acc, next) => (next ? acc + 1 : acc),
          0,
        ) > 1,
    };
  }

  goToAccountRegistration(): void {
    this.analytics.logClickCreateAccountEvent();
    this.router.navigate(['/account-registration']);
  }
}