<div class="u-margin-bottom-large">

  <div class="c-acc-panel c-acc-panel--header">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__heading">
        <h2 id="myhealthprofile" class="c-heading-acc">{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.MyHealthProfile' | translate}}</h2>
      </div>
      <div class="c-acc-panel__btn">
        <div>
          <a id="my-health-profile_link" class="c-link-ui" [routerLink]="['/my-account/my-health-profile']">{{ 'MY-ACCOUNT.Change' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="c-acc-panel c-acc-panel--stacked">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.WhenTherapyStarted' | translate}}</span>
      </div>
      <div class="c-acc-panel__value">
        <span>
          {{getStartTherapyGroupName(healthProfile.startTherapyGroup) | translate}}
        </span>
      </div>
    </div>

    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.HowManyEventsDetected' | translate}}</span>
      </div>
      <div class="c-acc-panel__value">
        <span *ngIf="healthProfile.userEnteredAhi">{{healthProfile.userEnteredAhi}} {{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.EventsPerHour' | translate}}</span>
      </div>
    </div>

    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.WhereWasSleepTest' | translate}}</span>
      </div>
      <div class="c-acc-panel__value">
        <span>{{getSleepTestTypeName(healthProfile.sleepTestType) | translate}}</span>
      </div>
    </div>

  </div>
</div>
