const countryCodeExpression = /loc=([\w]{2})/;

const DEFAULT_COUNTRY_CODE = 'US';

// This needs to be referenced via web-config.json. Bad to duplicate this as it was also out of date (albeit not affecting anything critical)
const SUPPORTED_COUNTRIES = [
    "CA", "AU", "US", "NZ", // AMR
    "JP", "TW", "TH", // APAC
    "BR", "MX", "AR", "CL", "CO", //LATAM
    "GB", "IE", "DE", "FI", "CH", "NL", "SE", "NO", "PL", "ES", "DK", "BE", "FR", "PT", "AT", "CZ", "GR", "IT", "CY", "ZA", "IS", "MT" // EU
];

export function getCountryCode(): string {
  if (typeof window !== 'undefined') {
    const storedCountry = window.localStorage.CurrentMyAirCountryId;

    if (storedCountry) {
      return storedCountry;
    }
    const request = new XMLHttpRequest();

    request.open('GET', 'https://1.1.1.1/cdn-cgi/trace', false); // This is the direct IP address of the Cloudflare cdn-cgi trace
    request.send(null);

    if (request.status === 200) {
      const response = countryCodeExpression.exec(request.responseText);
      if (response !== null && response[1] !== '') {
        return response[1];
      }
    }
  }

  return DEFAULT_COUNTRY_CODE;
}

export function getSupportedCountries(): string[] {
  return SUPPORTED_COUNTRIES;
}