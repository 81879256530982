import {
  UntypedFormGroup,
  UntypedFormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { DayJSService } from 'src/app/services/dayJS/dayJS.service';
/**
 * Describes custom validators for registration page
 */
export class RegistrationValidators {
  /**
   * Checks if patient selected country
   * If country wasn't selected returns error 'countrySelected' = false
   */
  public static isCountrySelected(countryControl: UntypedFormControl): { [key: string]: boolean } {
    return countryControl.value === '' ? { countrySelected: false } : null;
  }

  /**
   * Checks if patient selected time zone
   * If time zone wasn't selected returns error 'timeZoneSelected' = false
   */
  public static isTimeZoneSelected(timeZoneControl: UntypedFormControl): { [key: string]: boolean } {
    return timeZoneControl.value === '' ? { timeZoneSelected: false } : null;
  }

  /**
   * Checks if patient accepted the terms of use
   * If patient didn't accept it returns error 'termsOfUseAccepted' = false
   */
  public static isAcceptedTermsOfUse(legalConsentControl: UntypedFormControl): { [key: string]: boolean } {
    return legalConsentControl.value === true ? null : { termsOfUseAccepted: false };
  }

  /**
   * Checks if patient accepted the processing health data
   * If patient didn't accept it returns error 'processingHealthDataAccepted' = false
   */
  public static isAcceptedProcessingHealthData(
    legalConsentControl: UntypedFormControl,
  ): { [key: string]: boolean } {
    return legalConsentControl.value === true ? null : { processingHealthDataAccepted: false };
  }

  /**
   * Checks if patient confirmed email by re-typing it in Confirm email field
   * If email wasn't confirmed returns error 'emailNotConfirmed' = true
   */
  public static isEmailNotConfirmed(emailControl: UntypedFormGroup): { [key: string]: boolean } {
    return (emailControl.get('confirmEmail').value as string).trim() ===
      (emailControl.get('email').value as string).trim()
      ? null
      : { emailNotConfirmed: true };
  }

  /**
   * Checks if entered birthday is valid date
   * @param birthdayControl control ref
   */
  public static isBirthdayCorrect(dayJSService: DayJSService, minimumAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDay: number = control.get('day').value;
      const birthMonth: string = control.get('month').value;
      const birthYear: string = control.get('year').value;
      const dateFormat = 'YYYY-MM-DD';

      if (birthDay !== null && birthMonth !== '' && birthYear !== '') {
        // Format yyyy/mm/dd
        // Format yyyy/mm/dd
        const date = dayJSService.dayJS(
          `${birthYear}-${dayJSService
            .dayJS(birthMonth, 'MMM')
            .format('MM')}-${birthDay.toString().padStart(2, '0')}`,
          dateFormat,
          true,
        );
        if (date.isValid() && dayJSService.dayJS().diff(date.format(dateFormat), 'day') <= 0) {
          return { dateInTheFuture: true };
        }
        if (
          date.isValid() &&
          dayJSService.dayJS().diff(date.format(dateFormat), 'year') < minimumAge
        ) {
          return { youngerThanMinimumAge: true };
        }
        if (date.isValid() && date.diff('1900-12-31', 'day') <= 0) {
          return { invalidYear: true };
        }
        return !date.isValid() && (control.touched || control.dirty)
          ? { dateIsInvalid: true }
          : null;
      }
      return null;
    };
  }

  /**
   * Checks if birthday values are not set
   * @param birthdayControl control ref
   */
  public static isBirthdaySet(birthdayControl: UntypedFormGroup): { [key: string]: boolean } {
    if (
      (birthdayControl.get('year').errors?.required && birthdayControl.get('year').touched) ||
      (birthdayControl.get('month').errors?.required && birthdayControl.get('month').touched) ||
      (birthdayControl.get('day').errors?.required && birthdayControl.get('day').touched)
    ) {
      return { required: true };
    }
    return null;
  }

  public static isEmailCorrect(emailControl: UntypedFormControl): { [key: string]: boolean } {
    return !(emailControl.value as string)
      .trim()
      .match(/^\w+([-+.]\w+)*@\w+([_.-]\w+)*\.\w+([-.]\w+)*$/g) &&
      (emailControl.value as string) !== ''
      ? { pattern: true }
      : null;
  }

  public static restrictFirstNumberAsZero(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) return null;
    const test = /^0[\d]+(.?)(\d+)?$/.test(control.value);
    if (test) return { firstZeroRestricted: true };
    return null;
  }

  public static passwordsConditions(control: UntypedFormControl): ValidationErrors | null {
    const errors = {
      lengthError: true,
      lowercaseError: true,
      uppercaseError: true,
      numbersError: true,
      specialCharsError: true,
    };

    if (control.value.length >= 10) errors.lengthError = false;
    if (/[a-z]/.test(control.value)) errors.lowercaseError = false;
    if (/[A-Z]/.test(control.value)) errors.uppercaseError = false;
    if (/[0-9]/.test(control.value)) errors.numbersError = false;
    if (/\W|_/g.test(control.value)) errors.specialCharsError = false;

    return Object.values(errors).some((v) => v) ? errors : null;
  }

  public static checkForMatching(
    firstControlName: string,
    secondControlName: string,
    errorName = 'matchError',
  ) {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const firstValue = formGroup.get(firstControlName).value || '';
      const secondValue = formGroup.get(secondControlName).value || '';
      return firstValue?.trim() === secondValue?.trim() ? null : { [errorName]: true };
    };
  }
}
