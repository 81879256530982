<footer id="footer" class="page-footer" role="contentinfo">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item u-3/5@desktop page-footer__links">
        <a [routerLink]="[ '/policies/terms-of-use']" class="page-footer__links-item lnk-TermsofUse"><span>{{'FOOTER.TermsofUse' | translate}}</span></a>
        <a [routerLink]="[ '/policies/privacy-notice']" class="page-footer__links-item lnk-PrivacyPolicy"><span>{{'FOOTER.PrivacyPolicy' | translate}}</span></a>
        <a [routerLink]="[ '/policies/cookie-notice']" class="page-footer__links-item lnk-CookieNotice"><span>{{'FOOTER.CookieNotice' | translate}}</span></a>
        <a *ngIf="showLegalDisclaimer" (click)="openLegalDisclaimer()" class="page-footer__links-item lnk-LegalDisclaimer"><span>{{'FOOTER.LegalDisclaimer' | translate}}</span></a>
        <a *ngIf="!isNotSupportedCountry" [routerLink]="['/support']" class="page-footer__links-item">{{'FOOTER.Support' | translate}}</a>
      </div>

      <div class="o-layout__item u-2/5@desktop page-footer__copyright">
        <p><span translate [translateParams]="translateParams">FOOTER.Copyright</span></p>
      </div>
    </div>

    <div id="footerLanguages" class="page-footer__languages" *ngIf="isAuthenticationSet && !isAuthenticated && selectedCountry?.languages.length > 1">
      <ul class="page-footer__languages-list">
        <li class="page-footer__languages-list-item" *ngFor="let languageId of getLanguageIds()">
          <a (click)="selectLanguage(languageId)" [ngClass]="{ 'is-active': languageId === selectedLanguageId }"
            class="page-footer__languages-link"><span>{{ 'LANGUAGES.' + languageId | translate }}</span></a>
        </li>
      </ul>
    </div>
  </div>
</footer>
