import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { LanguageService } from 'src/app/services/language/language.service';
import { AuthService } from '@auth/auth.service';
import { LocalStorageService } from 'src/app/services/storage/storage.service';
import { CountryService } from '../country/country.service';

@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  private readonly HANDSET_PLATFORM = 'Web';
  private readonly consumingVariantKey = 'ConsumingVariantKey';
  private consumingVariant: string = null;
  private handsetIdKey = 'HandsetId';
  constructor(
    private languageService: LanguageService,
    private deviceDetector: DeviceDetectorService,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private countryService: CountryService,
  ) {}

  public getHeadersForApiMyAirRequest(): HttpHeaders {
    let headers = new HttpHeaders({
      'x-api-key': environment.api_keys.patientApiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      RMDAppVersion: environment.app_info.app_version,
      RMDHandsetId: !this.localStorage.getItem(this.handsetIdKey) ? 'Too-Early-To-Call-GetHandsetID' : this.getHandsetId(), // If we determine it is too early to call GetHandsetId, do not call it
      RMDHandsetModel: this.deviceDetector.browser,
      RMDHandsetOSVersion: this.deviceDetector.browser_version,
      RMDHandsetPlatform: this.HANDSET_PLATFORM,
      RMDCountry: this.countryService.getSelectedCountryId(),
      RMDLanguage: this.languageService.getCurrent2LettersLanguage(),
      RMDProduct: this.getRMDProduct(),
    });
    if (this.authService.accessToken) {
      headers = headers.append('Authorization', `Bearer ${this.authService.accessToken}`);
    }
    return headers;
  }

  // do not send authorization header for static myair requests
  public getHeadersForStaticMyAirRequest(): HttpHeaders {
    let headers = new HttpHeaders({
      'x-api-key': environment.api_keys.patientApiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      RMDAppVersion: environment.app_info.app_version,
      RMDHandsetId: !this.localStorage.getItem(this.handsetIdKey) ? 'Too-Early-To-Call-GetHandsetID' : this.getHandsetId(), // If we determine it is too early to call GetHandsetId, do not call it
      RMDHandsetModel: this.deviceDetector.browser,
      RMDHandsetOSVersion: this.deviceDetector.browser_version,
      RMDHandsetPlatform: this.HANDSET_PLATFORM,
      RMDCountry: this.countryService.getSelectedCountryId(),
      RMDLanguage: this.languageService.getCurrent2LettersLanguage(),
      RMDProduct: this.getRMDProduct(),
    });
    return headers;
  }

  public getRmdHeadersForApollo(): Record<string, unknown> {
    return {
      Authorization: `Bearer ${this.authService.accessToken}`,
      'x-api-key': environment.api_keys.graphQLApiKey,
      RMDAppVersion: environment.app_info.app_version,
      RMDHandsetId: this.getHandsetId(),
      RMDHandsetModel: this.deviceDetector.browser,
      RMDHandsetOSVersion: this.deviceDetector.browser_version,
      RMDHandsetPlatform: this.HANDSET_PLATFORM,
      RMDCountry: this.countryService.getSelectedCountryId(),
      RMDLanguage: this.languageService.getCurrent2LettersLanguage(),
      RMDProduct: this.getRMDProduct(),
    };
  }

  public isHeaderItemsAvailable(): boolean {
    return (
      this.localStorage.getItem(this.handsetIdKey) != null &&
      this.countryService.getSelectedCountryId() != null &&
      this.languageService.getCurrent2LettersLanguage() != null
    );
  }

  private getHandsetId(): string {
    if (this.localStorage.getItem(this.handsetIdKey) === null) {
      this.localStorage.setItem(this.handsetIdKey, uuidv4());
      this.authService.registerApplication().subscribe();
    }
    return this.localStorage.getItem(this.handsetIdKey);
  }

  private getRMDProduct(): string {
    // If we have determined that there is a more accurate variant to use post-collapse via the metadata, use it
    this.consumingVariant = this.localStorage.getItem(this.consumingVariantKey);

    if (!!this.consumingVariant) {
      return this.consumingVariant;
    }

    const id = this.countryService.getSelectedCountryId();

    switch (id) {
      case 'CA':
        return 'myAir Canada';
      case 'AR':
      case 'BR':
      case 'CL':
      case 'CO':
      case 'MX':
        return 'myAir LATAM';
      case 'JP':
      case 'TW':
      case 'TH':
        return 'myAir APAC';
      case 'US':
      case 'AU':
      case 'NZ':
        return 'myAir';
      default:
        return 'myAir EU';
    }
  }
}