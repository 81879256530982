export function getFirebaseConfig(country: string): { [key: string]: string | boolean } {
  switch (country) {
    case 'CA':
    case 'AR':
    case 'BR':
    case 'CL':
    case 'CO':
    case 'MX':
    case 'JP':
    case 'TW':
    case 'TH':
    case 'US':
    case 'AU':
    case 'NZ':
    default:
      return {
        apiKey: 'AIzaSyC5oPR5XIuqwv7mpRakRpDwbRVJvfb_mvU',
        authDomain: 'myair-stg.firebaseapp.com',
        databaseURL: 'https://myair-stg.firebaseio.com',
        projectId: 'myair-stg',
        storageBucket: 'myair-stg.appspot.com',
        messagingSenderId: '468499825007',
        appId: '1:468499825007:web:e3243c4266c0477da2e89a',
        measurementId: 'G-CLLT7E0BCV',
      };
    case 'GB':
    case 'IE':
    case 'DE':
    case 'FI':
    case 'CH':
    case 'NL':
    case 'SE':
    case 'NO':
    case 'PL':
    case 'ES':
    case 'DK':
    case 'BE':
    case 'FR':
    case 'PT':
    case 'AT':
    case 'CZ':
    case 'GR':
    case 'IT':
    case 'CY':
    case 'ZA':
    case 'IS':
    case 'MT':
      return {
        apiKey: 'AIzaSyCBF_XiDu5U6iWGJrsJe04k01bFQ-zu0Gc',
        authDomain: 'myair-eu-stg.firebaseapp.com',
        databaseURL: 'https://myair-eu-stg.firebaseio.com',
        projectId: 'myair-eu-stg',
        storageBucket: 'myair-eu-stg.appspot.com',
        messagingSenderId: '183845397836',
        appId: '1:183845397836:web:7e7b9ca6c698ea0b59dbe9',
        measurementId: 'G-T4Y7K949FD',
      };
  }
}
