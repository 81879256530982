import { Injectable } from '@angular/core';
import { UrlTree, Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import { AuthService } from '@auth/auth.service';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '../storage/storage.service';
import { CountryId } from 'src/app/types/country.type';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class CountrySelectedGuard  {
  constructor(
    private countryService: CountryService,
    private router: Router,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private metadataService: MetadataService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route);
  }
  canLoad(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route);
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.metadataService.checkIfMetadataLocalLoadNecessary();
    // Check to see if the query string provided country parameters
    const urlParams = new URLSearchParams(window.location.search);
    const countryId: CountryId = urlParams.get('redirectCountry') as CountryId;
    const appOnlyCountries = await this.countryService.loadAppOnlyCountries().toPromise();
    const supportedCountries = await this.countryService.loadSupportedCountries().toPromise();
    
    if (countryId) {
      if (appOnlyCountries.includes(countryId)) {
        if (countryId === 'LU') {
          this.router.navigate(['/myair-emea']);
        } else {
          this.router.navigate(['/myair-asia']);
        }
      } else if (supportedCountries.includes(countryId)) {
        this.countryService.setSelectedCountryId(countryId);
        this.metadataService.checkIfMetadataApiLoadNecessary();
        // You don't want to load the Mask Metadata on home,
        // as it is not necessary and would get reloaded on dashboard due to login
        // changing country/language potentially
        if (!route?.data || !route.data['skipMaskMetadata']) {
          this.metadataService.checkIfMaskMetadataLoadNecessary();
        }
        return true;
      }
    }
    if (!supportedCountries.includes(this.countryService.getSelectedCountryId())) {
      // if user is authenticated we should logout the user before
      // redirecting to country-selection page
      if (await this.authService.checkAuthenticated()) {
        await this.authService.logout();
      }
      // Snag the first router event that has the targeted URL and place it in localStorage to be used later in country service
      this.router.events.pipe(take(1)).subscribe(event => {
        //TODO: Remove the if when we have time to fully test
        if ((event as NavigationEnd).url === '/policies/cookie-notice') {
          this.localStorage.setItem(this.localStorage.redirectURL, (event as NavigationEnd).url);
        }
      });
      return this.router.createUrlTree(['country-selection']);
    }
    // You don't want to load the Mask Metadata on home,
    // as it is not necessary and would get reloaded on dashboard due to login
    // changing country/language potentially
    if (!route?.data || !route.data['skipMaskMetadata']) {
      this.metadataService.checkIfMaskMetadataLoadNecessary();
    }
    this.metadataService.checkIfMetadataApiLoadNecessary();
    return true;
  }
}