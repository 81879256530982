<div class="c-acc-panel c-acc-panel--read">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label u-1/2">
      <span>{{ 'MY-ACCOUNT/MY-PRIVACY.DownloadMyAccountInfo' | translate}}</span>
    </div>
    <div class="c-acc-panel__btn u-1/2">
      <button class="c-link-ui" (click)="edit(); analyticsService.logScreenDownloadMyDataEvent()">{{ 'MY-ACCOUNT/MY-PRIVACY.Download' | translate}}</button>
    </div>
  </div>
</div>
