// used in language selection dropdown and footer language selection. These get translated into what we actually see in the dropdown (EnglishUk --> English (UK))
export const languageIds = [
  'EnglishUs',
  'German',
  'EnglishUk',
  'French',
  'Spanish',
  'Dutch',
  'Norwegian',
  'Polish',
  'Suomi',
  'Swedish',
  'PortugueseBrazil',
  'Portuguese',
  'Japanese',
  'Czech',
  'FrenchCanadian',
  'SpanishLatAm',
  'Greek',
  'ChineseTraditional',
  'Italian',
  'Danish'
] as const;

// used in main.component.ts to add languages to ngx-translate's TranslateService
export const cultureLanguageIds = [
  'en-us',
  'de-de',
  'en-gb',
  'fr-fr',
  'es-es',
  'nl-nl',
  'nb-no',
  'pl-pl',
  'fi-fi',
  'sv-se',
  'pt-br',
  'ja-jp',
  'cs-cz',
  'fr-ca',
  'es-419',
  'pt-pt',
  'el-gr',
  'zh-tw',
  'it-it',
  'da-da'
] as const;

// A list of language codes the Chrome browser uses. This constant is used to create CultureLanguage2LetterId type, which is used on browserCultureLanguage, set the RMDLanguage header, and...
export const cultureLanguage2LettersIds = [
  'en',
  'de',
  'en-GB',
  'fr',
  'es-ES',
  'nl',
  'nb',
  'pl',
  'fi',
  'sv',
  'pt-BR',
  'pt-PT',
  'pt', // needed for properly setting portuguese browserCultureLanguage since Chrome supports pt and pt-PT
  'ja',
  'cs',
  'fr-CA',
  'es',
  // needed for properly setting spanishLatam browserCultureLanguage since Chrome supports es-419, es-US, es-MX, es-AR, es-CL, es-CO
  'es-419',
  'es-US',
  'es-MX',
  'es-AR',
  'es-CL',
  'es-CO',
  'el-GR',
  'el',
  'zh-TW',
  'zh-Hant',
  'it',
  'da'
] as const;

export type LanguageId = typeof languageIds[number];
export type CultureLanguageId = typeof cultureLanguageIds[number];
export type CultureLanguage2LetterId = typeof cultureLanguage2LettersIds[number];

export const languages: Record<LanguageId, CultureLanguageId> = {
  EnglishUs: 'en-us',
  German: 'de-de',
  EnglishUk: 'en-gb',
  French: 'fr-fr',
  Spanish: 'es-es',
  Dutch: 'nl-nl',
  Norwegian: 'nb-no',
  Polish: 'pl-pl',
  Suomi: 'fi-fi',
  Swedish: 'sv-se',
  PortugueseBrazil: 'pt-br',
  Portuguese: 'pt-pt',
  Japanese: 'ja-jp',
  Czech: 'cs-cz',
  FrenchCanadian: 'fr-ca',
  SpanishLatAm: 'es-419',
  Greek: 'el-gr',
  ChineseTraditional: 'zh-tw',
  Italian: 'it-it',
  Danish: 'da-da'
} as const;

// used in language.service.ts to getLanguageByBrowser() and getCurrent2LettersLanguage()
// used in the RMDLanguageHeader we send to Hyperdrive. These values must match what Hyperdrive expects for authentication in that language to work.
export const languages2Letters: Record<LanguageId, CultureLanguage2LetterId> = {
  EnglishUs: 'en',
  German: 'de',
  EnglishUk: 'en-GB',
  French: 'fr',
  Spanish: 'es-ES',
  Dutch: 'nl',
  Norwegian: 'nb',
  Polish: 'pl',
  Suomi: 'fi',
  Swedish: 'sv',
  PortugueseBrazil: 'pt-BR',
  Portuguese: 'pt', // Portuguese must stay 'pt' here to match Hyperdrive's expectations for RMDLanguage header, otherwise user authentication will break.
  Japanese: 'ja',
  Czech: 'cs',
  FrenchCanadian: 'fr-CA',
  SpanishLatAm: 'es', // SpanishLatAm must stay 'es' here to match Hyperdrive's expectations for RMDLanguage header, otherwise user authentication will break.
  Greek: 'el',
  ChineseTraditional: 'zh-Hant',
  Italian: 'it',
  Danish: 'da'
} as const;
