<div class="flex orientation wrapper">
  <img class="main-image" *ngIf="currentVideo === 'line' && lineSelected === 'climateline' && !imageSelected" src='../../../assets/images/climateline.png'/>
  <img class="main-image" *ngIf="currentVideo === 'line' && lineSelected === 'slimline' && !imageSelected" src='../../../assets/images/slimline.png'/>
  <img class="main-image" *ngIf="currentVideo === 'line' && imageSelected === 'airCurve'" src='../../../assets/images/aircurve11.png'/>
  <img class="main-image" *ngIf="currentVideo === 'line' && imageSelected === 'airSense'" src='../../../assets/images/airsense11.png'/>
  <video class="main-image" *ngIf="currentVideo !== 'line' && currentVideo !== null" controls autoplay loop (play)="playAudio(currentAudio)">
    <source [src]="currentVideo" type="video/mp4">
  </video>
  <div class="pta-stepper">
    <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
        <ng-template matStepperIcon="edit">
            <svg class="c-svg c-svg--checkmark">
                <use xlink:href="#c-svg--checkmark"></use>
            </svg>
        </ng-template>
        <mat-step #machineStep *ngIf="!!pta.machineAssets && !!pta.displayMachine">
          <ng-template matStepLabel>
            <div class="step-label">Machine setup</div>
          </ng-template>
          <div class="flex column space-between padding-top">
            <div>
              {{currentText}}
            </div>
            <div *ngIf="currentVideo === 'line'">
              <mat-radio-group class="flex column" [(ngModel)]="lineSelected">
                <mat-radio-button [value]="'climateline'">Climateline</mat-radio-button>
                <mat-radio-button [value]="'slimline'">Slimline</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="button-styling">
              <button class="c-btn c-btn--tertiary" (click)="determineAudioVideoDescription(false)" *ngIf="currentVideo !== 'line'">Back</button>
              <button class="c-btn c-btn--primary" (click)="determineAudioVideoDescription(true)">Next</button>
            </div>
          </div>
          
        </mat-step>
      
        <mat-step #maskStep *ngIf="!!pta.maskAssets && !!pta.displayMask">
          <ng-template matStepLabel>
            <div class="step-label">Mask setup</div>
          </ng-template>
          <div class="flex column space-between padding-top">
            <div>
              {{currentText}}
            </div>
            <div class="button-styling">
              <button class="c-btn c-btn--tertiary" (click)="determineAudioVideoDescription(false);" *ngIf="!!(pta.displayMachine || currentStep !== 0)">Back</button>
              <button class="c-btn c-btn--primary" (click)="determineAudioVideoDescription(true)">Next</button>
            </div>
          </div>
        </mat-step>
      
        <mat-step #finish>
          <ng-template matStepLabel>
            <div class="step-label">Finish</div>
          </ng-template>
          <div class="flex column space-between padding-top">
            <div>
              {{currentText}}
            </div>
            <div class="button-styling">
              <button class="c-btn c-btn--tertiary" (click)="restartButton()">Restart</button>
              <button class="c-btn c-btn--primary" (click)="pta.doNotLaunchLibraryModal = false; closeModal();">Done</button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
  </div>
  <svg class="c-svg c-svg--close-24" (click)="pta.doNotLaunchLibraryModal = false; closeModal()">
    <use xlink:href="#c-svg--close-24"></use>
  </svg>
</div>
