<div class="u-margin-bottom-large">
  <div>
    <div>
      <div class="js-acc-panel is-active" style="display: block; visibility: visible;">
        <mpp-notifications-view *ngIf="state === componentState.View"
          [notificationPreferences]="notificationPreferences" (stateChanged)="state = $event"></mpp-notifications-view>
        <mpp-notifications-edit *ngIf="state === componentState.Edit"
          [notificationPreferences]="notificationPreferences" (stateChanged)="state = $event"
          (notificationsChanged)="notificationsChanged($event)"></mpp-notifications-edit>
        <mpp-notifications-error *ngIf="state === componentState.Error" (stateChanged)="state = $event"></mpp-notifications-error>
      </div>
    </div>
  </div>
</div>
