import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { PatientService } from '../patient/patient.service';
import { HeadersService } from '../showtime-api/headers.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceRegisteredGuard  {
  constructor(
    private router: Router,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private headersService: HeadersService,
    private authService: AuthService
  ) {}

  canActivateChild(): Promise<boolean | UrlTree> | boolean {
    return this.canActivate();
  }

  canLoad(): Promise<boolean | UrlTree> | boolean {
    return this.canActivate();
  }

  async canActivate(): Promise<boolean | UrlTree> {
    // Ensure the patient is authenticated and has the expected list of headers
    // before checking if they have a device assigned.
    if (!await this.authService.checkAuthenticated() || !this.headersService.isHeaderItemsAvailable()) {
      return false;
    }

    const spinner = this.spinnerService.show();
    return this.patientService
      .getDevices()
      .toPromise()
      .then(([devices]) => {
        spinner.hide();
        if (devices) {
          return true;
        }
        return this.router.createUrlTree(['register-device']);
      })
      .catch((error) => {
        spinner.hide();
        //If we have any GraphQLErrors, then the call was successfully answered by AppSync but there was no device.
        error.graphQLErrors?.forEach(error => {
          if(error.errorInfo.errorCode === "equipmentNotAssigned"){
            return this.router.createUrlTree(['register-device']);
          }
        });
        //If there was an error, but there are no graphQLErrors, then the call did not successfully reach AppSync (or returned an error).
        return false;
      });
  }
}