/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPatientWrapper = /* GraphQL */ `
  query GetPatientWrapper {
    getPatientWrapper {
      patient {
        id
        email
        countryId
        firstName
        lastName
        dateOfBirth
        gender
        sleepTestType
        startTherapyGroup
        userEnteredAhi
        phoneNumber
        aboutMeProgress
        myEquipmentProgress
        myHealthProgress
        overallProgress
        achievementsPushEnabled
        coachingPushEnabled
        coachingSmsEnabled
        coachingEmailEnabled
        cleaningPushEnabled
        cleaningSmsEnabled
        cleaningEmailEnabled
        smartCoachingEnabled
        stepCountSharingEnabled
        healthSharingEnabled
        shareDetailsWithProviderOptIn
        analyticsMode
        analyticsId
        pushToken
        timezoneId
        furiganaFamilyName
        furiganaGivenName
        marketingOptIn
        achievements {
          id
          name
          description
          imageUrl
          earnedDate
          __typename
        }
        __typename
      }
      fgDevices {
        serialNumber
        deviceType
        deviceSeries
        deviceFamily
        lastSleepDataReportTime
        localizedName
        imagePath
        imageUrl
        fgDeviceManufacturerName
        fgDevicePatientId
        __typename
      }
      masks {
        maskManufacturerName
        maskCode
        maskType
        localizedName
        imagePath
        imageUrl
        maskPatientId
        __typename
      }
      coachingHistory {
        items {
          date
          sleepLibraryId
          __typename
        }
        lastEvaluatedKey
        __typename
      }
      sleepLibrary {
        title
        thumbnail
        description
        videoOrHtmlUrl
        contentId
        language
        sleepLibraryId
        section
        type
        sleepLibraryPatientId
        analyticsScreenName
        videoId
        __typename
      }
      sleepRecords {
        items {
          startDate
          totalUsage
          sleepScore
          usageScore
          ahiScore
          maskScore
          leakScore
          ahi
          maskPairCount
          leakPercentile
          sleepRecordPatientId
          __typename
        }
        lastEvaluatedKey
        __typename
      }
      notifications {
        id
        action
        title
        description
        positiveButtonTitle
        negativeButtonTitle
        url
        negativeActionId
        negativeActionContent {
          title
          description
          positiveButtonTitle
          negativeButtonTitle
          __typename
        }
        achievement {
          id
          name
          description
          imageUrl
          earnedDate
          __typename
        }
        __typename
      }
      dashboard {
        sectionsV1 {
          sectionType
          __typename
        }
        sectionsV2 {
          sectionType
          __typename
        }
        sectionsV3 {
          sectionType
          __typename
        }
        sectionsV4 {
          sectionType
          __typename
        }
        __typename
      }
      healthTrends {
        usage {
          previousAverage
          currentAverage
          trend
          __typename
        }
        stepCount {
          previousAverage
          currentAverage
          trend
          __typename
        }
        weightKilograms {
          previousAverage
          currentAverage
          trend
          __typename
        }
        activeCaloriesBurned {
          previousAverage
          currentAverage
          trend
          __typename
        }
        __typename
      }
      achievements {
        id
        name
        description
        imageUrl
        earnedDate
        __typename
      }
      goalTrackingDates {
        goalPeriodStartDate
        goalPeriodEndDate
        __typename
      }
      __typename
    }
  }
`;
export const getNightowlPatientWrapper = /* GraphQL */ `
  query GetNightowlPatientWrapper {
    getNightowlPatientWrapper {
      dashboard {
        sectionsV4 {
          sectionType
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMaskQuestions = /* GraphQL */ `
  query GetMaskQuestions($maskCode: String!, $locale: String!) {
    getMaskQuestions(maskCode: $maskCode, locale: $locale) {
      questionId
      localizedQuestion
      answers {
        answerId
        localizedAnswer
        __typename
      }
      __typename
    }
  }
`;
