<div class="c-notifications-wrapper" #notificationWrapper>

  <div class="c-alert c-alert--warning js-toggle-data-state"
       role="alert"
       (animationend)="animationend(alert, $event)"
       [attr.data-state]="alert.isDismissed ? 'is-dismissed' : 'is-active'"
       *ngFor="let alert of alerts; let i = index">
    <div class="icon-wrapper">
      <object [id]="i" [data]="imageUrls[i]" type="image/svg+xml" width="24" height="24"></object>
    </div>
    <div class="c-alert__body">
      <p>
        <span [ngClass]="alert.isPatientMessaging ? 'main-message': ''">{{ alert.message | translate }}</span><span class="main-message" *ngIf="alert.isPatientMessaging"> - </span>
          <span *ngFor="let link of alert.links">
            <a (click)="link.callback(alert)">{{ link.title | translate }}</a>
            <span *ngIf="link.textAfterLink !== null">
              {{link.textAfterLink | translate}}</span>
          </span>
      </p>
    </div>
    <div class="c-alert__close" *ngIf="alert.isDismissable">
      <button (click)="onClose(alert)" class="c-icon-btn c-icon-btn--close" aria-label="Close">
        <svg class="c-svg c-svg--close-24">
          <use xlink:href="#c-svg--close-24"></use>
        </svg>
      </button>
    </div>
  </div>
</div>
