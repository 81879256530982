<section class="o-section">
  <div class="o-wrapper">

    <div class="o-layout">
      <div class="o-layout__item  [ u-2/4@desktop u-push-1/4@desktop ]">
        <div>
          <form [formGroup]="form" (ngSubmit)="forgotPassword()">
            <h1 class="c-heading-page">{{"FORGOT-PASSWORD.ForgotPassword" | translate}}</h1>
            <ng-container *ngIf="!requestSendSuccessfully">

              <p>{{"FORGOT-PASSWORD.EnterEmailAddress" | translate}}</p>

              <div class="c-form-field  [ u-7/12@tablet u-9/12@desktop ]">
                <label class="c-form-field__label"
                  for="email-address">{{"FORGOT-PASSWORD.Email" | translate}}</label>
                <input id="email-address" formControlName="email" type="email" class="c-input c-input--text">
                <span class="c-form-field__error" role="alert" aria-live="assertive"
                  *ngIf="form.get('email').touched && form.get('email').errors?.required">
                  {{"ERRORS.Required" | translate}}</span>
                <span class="c-form-field__error" role="alert" aria-live="assertive"
                  *ngIf="form.get('email').touched && form.get('email').errors?.pattern">
                  {{"ERRORS.IncorrectEmailFormat" | translate}}</span>
                <span class="c-form-field__error" role="alert" aria-live="assertive"
                  *ngIf="form.get('email').touched && form.get('email').errors?.badRequest">
                  <span>{{'ERRORS.ForgotPassword.CannotResetPassword' | translate}}</span>
                  <a [routerLink]="['/support']">{{'ERRORS.ForgotPassword.Support' | translate}}</a>
                  <span>{{'ERRORS.ForgotPassword.ForHelp' | translate}}</span>
                </span>
              </div>


              <div class="c-form-buttons  [ o-layout o-layout--auto ]">
                <div class="o-layout__item">
                  <button type="submit" [disabled]="!form.valid" class="c-btn c-btn--primary"><span
                      class="text">{{'FORGOT-PASSWORD.Send' | translate}}</span></button>
                </div>
                <div class="o-layout__item">
                  <a [routerLink]="['/']" class="c-btn c-btn--tertiary"><span
                      class="text">{{'FORGOT-PASSWORD.Cancel' | translate}}</span></a>
                </div>

              </div>
            </ng-container>
            <ng-container *ngIf="requestSendSuccessfully">
              <h2>{{'FORGOT-PASSWORD.EmailedInstructions.CheckYourEmail' | translate}}</h2>
              <p>{{'FORGOT-PASSWORD.EmailedInstructions.MyAirHasEmailed' | translate: {emailAddress: form.get('email').value} }}</p>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
