<div class="c-acc-panel c-acc-panel--read">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/MY-PRIVACY.AdditionalInformationEMEA' | translate}}</span>
    </div>
    <div class="c-acc-panel__value">
      <span *ngIf="privacySettings.marketingOptIn">{{ 'MY-ACCOUNT/MY-PRIVACY.Yes' | translate}}</span>
      <span *ngIf="!privacySettings.marketingOptIn">{{ 'MY-ACCOUNT/MY-PRIVACY.No' | translate}}</span>
    </div>
    <div class="c-acc-panel__btn">
      <a class="c-link-ui" (click)="edit()">{{ 'MY-ACCOUNT.Change' | translate}}</a>
    </div>
  </div>
</div>
