import { Component, OnInit } from '@angular/core';
import { PatientMessagingService } from 'src/app/modules/alert/components/services/patient-messaging.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
@Component({
  selector: 'mpp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(
    private patientMessagingService: PatientMessagingService,
    private languageService: LanguageService,
    private analytics: AnalyticsService
  ) {}
  
  ngOnInit() {
    // Get Patient Messaging if it exists and display it
    this.patientMessagingService.getPatientMessaging(this.languageService.selectedLanguageId);
    this.analytics.logLandedOnLoginScreenEvent();
  }
}