<div class="modal-wrapper">
    <div class="title">Setup help</div>
    <div class="text">The Personal Therapy Assistant can help you set up your machine and mask.</div>
    <div class="button-wrapper">
        <button class="c-btn c-btn--primary" (click)="openPTA()">Begin setup</button>
        <button class="c-btn c-btn--tertiary" (click)="closeModal()">No thanks</button>
    </div>
    <svg class="c-svg c-svg--close-24" (click)="closeModal()">
        <use xlink:href="#c-svg--close-24"></use>
    </svg>
</div>