import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OktaAuth, AccessToken, AuthTransaction } from '@okta/okta-auth-js'; // AuthTransaction
import { User } from 'src/app/models/user.model';
import { OKTA_AUTH } from 'src/app/services/okta/okta.service';
import { environment } from 'src/environments/environment';
import { MyAirUserClaims } from 'src/app/models/myair-user-claims';
import { RegisterApplicationError } from 'src/app/services/auth/register-application-error';
import { LocalStorageService } from 'src/app/services/storage/storage.service';
import { CountryService } from '../country/country.service';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from '@aws-sdk/client-cognito-identity';
import { MetadataService } from '../metadata/metadata.service';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ShowTimeApiService {
  public isAuthenticated$ = new BehaviorSubject<boolean>(undefined);
  public authenticatedUser$ = new BehaviorSubject<User>(null);
  private accessTokenString: string;
  public get accessToken(): string {
    return this.accessTokenString;
  }
  private idTokenObject;
  private cognitoIdentity: CognitoIdentityClient | null = null;
  public awsCredentials$ = new BehaviorSubject<any>(null);

  constructor(
    @Inject(OKTA_AUTH) private authClient: OktaAuth,
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private countryService: CountryService,
    private metadataService: MetadataService,
    protected alertService: AlertService,
  ) {
    super(alertService);
  }

  public checkAndRefreshAWSCredentials(): Promise<any> {
    const credentials = this.awsCredentials$.getValue();
    // Check if credentials have expired, and if so, refresh them
    if (this.credentialsHaveExpiredOrFalsy(credentials)) {
      // console.log("aws credentials have expired or are falsy!");
      return this.getAWSCredentials(this.idTokenObject).then(newCredentials => {
        this.awsCredentials$.next(newCredentials);
        return newCredentials;
      });
    } else {
      // If credentials are not expired, return a resolved Promise
      return Promise.resolve(credentials);
    } 
  }

  public credentialsHaveExpiredOrFalsy(credentials: any): boolean {
    if (!credentials || !credentials.Expiration) return true;
    const now = new Date();
    return now >= credentials.Expiration;
  }

  async getAWSCredentials(oktaIdToken): Promise<any> {
    try {
      if(!oktaIdToken) {
        oktaIdToken = await this.authClient.tokenManager.get('idToken');
      }

      let cognitoIdentityPoolId: string;

      const metadata = await this.metadataService.getMetadataFromApi().toPromise();
      cognitoIdentityPoolId = metadata.instanceInfo.smartCoachingCognitoIdentityPoolId;

      // Check if Smart Coaching AWS Region has been fetched from metadata. If not, fetch it and create new cognitoIdentityClient
      if (!this.cognitoIdentity) {
        const DEFAULT_REGION = metadata.instanceInfo.smartCoachingAWSRegion;
        if (DEFAULT_REGION && DEFAULT_REGION !== "TBD") {
          this.cognitoIdentity = new CognitoIdentityClient({ region: DEFAULT_REGION });
        } else {
          throw new Error("Default Region was not set.");
        }
      }

      // Check metadata for Cognito Identity Pool Id
      if (!cognitoIdentityPoolId) {
        throw new Error("Cognito Identity Pool Id was not set.");
      }

      // console.log("cognitoIdentityPoolId: ", cognitoIdentityPoolId);

      const idToken = oktaIdToken.idToken;
      // console.log("idToken: ", idToken);
      const url = new URL(oktaIdToken.claims.iss);
      // console.log("url: ", url);
      // removes 'https://' from beginning of the url, leaving only the issuer string
      const issuer = `${url.hostname}${url.pathname}`;
      // console.log("issuer: ", issuer);

      // Step 1: Get Cognito Identity
      const getIdParams = {
        // Fetch this from metadata
        // if empty, null or "TBD", bail out
        IdentityPoolId: cognitoIdentityPoolId,
        Logins: {
          // Identity provider 
          [issuer]: idToken
        }
      };
      // console.log("getIdParams: ", getIdParams);
      // console.log("getIdParams: ", getIdParams);

      const idResp = await this.cognitoIdentity.send(new GetIdCommand(getIdParams));
      // console.log("idResp: ", idResp);

      // Step 2: Get Cognito Credentials
      const getCredentialsParams = {
        IdentityId: idResp.IdentityId,
        Logins: {
          [issuer]: idToken
        }
      };
      // console.log("getCredentialsParams: ", getCredentialsParams);

      const credentialsResp = await this.cognitoIdentity.send(new GetCredentialsForIdentityCommand(getCredentialsParams));
      // console.log("credentialsResp: ", credentialsResp);

      const credentials = {
        AccessKeyId: credentialsResp.Credentials!.AccessKeyId,
        Expiration: credentialsResp.Credentials!.Expiration,
        SecretKey: credentialsResp.Credentials!.SecretKey,
        SessionToken: credentialsResp.Credentials!.SessionToken
      };
      // console.log("AWS credentials: ", credentials);

      this.awsCredentials$.next(credentials);
      return {
        AccessKeyId: credentialsResp.Credentials!.AccessKeyId,
        Expiration: credentialsResp.Credentials!.Expiration,
        SecretKey: credentialsResp.Credentials!.SecretKey,
        SessionToken: credentialsResp.Credentials!.SessionToken
      };

    }  catch (error) {
      this.showErrorAsync(error, { disableAlert: true });
    }
}

  checkAuthenticated(): Promise<boolean> {
    return this.hasValidAccessToken();
  }

  async login(tokens: any, password?: any): Promise<void> {
    this.accessTokenString = tokens.accessToken.accessToken;
    this.idTokenObject = tokens.idToken;
    // console.log("accessTokenString", this.accessTokenString);
    // console.log("this.idTokenObject", this.idTokenObject);
    try {
      await this.registerApplication().toPromise();
      await this.authClient.token.getUserInfo().then((response: MyAirUserClaims) => {
        const userInfo = User.fromUserClaims(response);
        this.countryService.selectCountry(userInfo.CountryId);
        this.authenticatedUser$.next(userInfo);
        this.isAuthenticated$.next(true);
      });
    } catch (error) {
      throw new RegisterApplicationError(error);
    }
  }

  async reLoginAfterPasswordChange(username: string, password: string): Promise<void> {
    const transaction: AuthTransaction = await this.authClient.signInWithCredentials({
      username,
      password,
    });

    if (transaction.status !== 'SUCCESS') {
      throw new Error(`status ${transaction.status}`);
    }

    const tokenResponse = await this.authClient.token.getWithoutPrompt({
      issuer: environment.okta.issuer,
      clientId: environment.okta.clientId,
      responseType: ['id_token', 'token'],
      scopes: environment.okta.scopes,
      sessionToken: transaction.sessionToken,
      redirectUri: environment.okta.redirectUri,
    });
    this.authClient.tokenManager.setTokens(tokenResponse.tokens);
    await this.login(tokenResponse.tokens);
  }

  setOnRenewedHandler(): void {
    this.authClient.tokenManager.on('renewed', async (key: string, newToken: AccessToken) => {
      this.accessTokenString = newToken.value;
      this.registerApplication().subscribe();
    });
  }

  async getUserInfo(): Promise<MyAirUserClaims> {
    if (!(await this.checkAuthenticated())) {
      this.authenticatedUser$.next(null);
      this.isAuthenticated$.next(false);
      return null;
    }
    const user = await this.authClient.token.getUserInfo();
    this.authenticatedUser$.next(User.fromUserClaims(user as MyAirUserClaims));
    this.isAuthenticated$.next(true);
    return user as MyAirUserClaims;
  }

  async hasValidAccessToken(): Promise<boolean> {
    const accessToken: any = await this.authClient.tokenManager.get('accessToken');

    if (accessToken) {
      const nowInSeconds = Math.trunc(new Date().getTime() / 1000);
      if (accessToken.expiresAt > nowInSeconds) {
        this.accessTokenString = accessToken.accessToken;
        this.isAuthenticated$.next(true);
        return true;
      }
      this.isAuthenticated$.next(false);
      return false;
    }
    this.isAuthenticated$.next(false);
    return false;
  }

  registerApplication(): Observable<unknown> {
    return this.httpClient.post(
      `${environment.endpoints.apiMyAir}/V1/Patient/RegisterApplication`,
      {},
    );
  }

  async logout(): Promise<void> {
    try {
      await this.authClient.signOut();
      this.isAuthenticated$.next(false);
      this.authenticatedUser$.next(null);
      this.localStorage.removeItem('DeviceType');
    } catch (err) {
      throw Error(`Error while sign out: ${err}`);
    }
  }
}
