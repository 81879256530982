import { Component, Inject, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LocalStorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { RegisterApplicationError } from 'src/app/services/auth/register-application-error';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { WINDOW_OBJECT } from 'src/app/services/window/window.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/component/base-component';
import { combineLatest } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PinpointService } from 'src/app/services/pinpoint/pinpoint.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'mpp-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends BaseComponent implements AfterViewInit, OnInit, OnDestroy {

  // Localized keys used to override values within the Okta widget.
  signInKey: string;
  emailKey: string;
  invalidEmailOrPasswordKey: string;
  multiFactorAuthenticationEmailSentKey: string;
  accountLockedKey: string;

  lastLoggedInKey = 'LastLoggedInEmail';
  logoutAlertKey = 'AlertOnLogout';

  oktaEmailButtonResendKey: string;
  oktaEmailCodeLabelKey: string;
  oktaEmailCodeNotReceivedKey: string;
  oktaEmailMfaDescKey: string;
  oktaEmailMfaTitleKey: string;
  oktaEmailResendKey: string;
  oktaEmailSendKey: string;
  oktaErrorConfigKey: string;
  oktaErrorEnabledCORSKey: string;
  oktaErrorExpiredSessionKey: string;
  oktaErrorJITFailureKey: string;
  oktaErrorMfaExpiredSessionKey: string;
  oktaErrorMfaRequiredKey: string;
  oktaErrorNetworkConnectionKey: string;
  oktaErrorOAuthIdTokenKey: string;
  oktaErrorPasswordRequiredKey: string;
  oktaErrorUnsupportedBrowserKey: string;
  oktaErrorUnsupportedCORSKey: string;
  oktaErrorUnsupportedLocalStorageKey: string;
  oktaErrorUnsupportedResponseKey: string;
  oktaErrorUnsyncedClockKey: string;
  oktaErrorUsernameRequiredKey: string;
  oktaErrorsE0000047Key: string;
  oktaErrorsE0000069Key: string;
  oktaErrorsE0000079Key: string;
  oktaErrorsEmailVerifyNotReceivedKey: string;
  oktaErrorsTitleKey: string;
  oktaForgotPasswordKey: string;
  oktaGoBackKey: string;
  oktaHelpKey: string;
  oktaMfaChallengeVerifyKey: string;
  oktaNeedHelpKey: string;
  oktaPasswordEmailSentKey: string;
  oktaPasswordEmailSentDescKey: string;
  oktaPasswordPlaceholderKey: string;
  oktaPasswordResetKey: string;
  oktaPasswordTooltipKey: string;
  oktaRememberDeviceKey: string;
  oktaResetPasswordEmailKey: string;
  oktaSubmitKey: string;
  oktaValidationFieldBlankKey: string;
  oktaValidationFieldInvalidKey: string;
  oktaValidationFieldValueNotAllowedKey: string;
  oktaValidationFieldWrongTypeKey: string;

  private OktaSignInWidget = OktaSignIn;
  private oktaSignIn: OktaSignIn;

  constructor(
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private analyticsService: AnalyticsService,
    private localStorage: LocalStorageService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private pinpointService: PinpointService,
    private metadataService: MetadataService,
    @Inject(WINDOW_OBJECT) public window: Window,
  ) {
    super();
  }
  async ngOnInit(): Promise<void> {

    const alertOnLogout = this.localStorage.getItem(this.logoutAlertKey);
    if (alertOnLogout) {
      this.alertService.info(alertOnLogout);
      this.localStorage.removeItem(this.logoutAlertKey);
    }
    combineLatest([
      this.translateService.getTranslation(this.translateService.currentLang).pipe(startWith(<any>null), takeUntil(this.unsubscribe$)),
      this.translateService.onLangChange.pipe(startWith(<LangChangeEvent>null), takeUntil(this.unsubscribe$))
    ]).subscribe(([unchanged, changed]) => {
      // If at least one value has been emitted validly
      if (unchanged || changed) {
        // Prioritize onLangChange results
        const data = changed ? changed.translations : unchanged;
        // Load all the localized strings from the appropriate translation files.
        this.signInKey = data?.HOME?.SignIntomyAir;
        this.emailKey = data?.HOME?.EmailAddress;
        this.invalidEmailOrPasswordKey = data?.ERRORS?.InvalidEmailOrPassword;
        this.multiFactorAuthenticationEmailSentKey = data?.HOME?.MultiFactorAuthenticationEmailSent;
        this.accountLockedKey = data?.ERRORS?.AccountLocked;
        // Load all the localized strings for languages that Okta does not support (pt-pt)
        this.oktaEmailButtonResendKey = data?.HOME?.OktaEmailButtonResend;
        this.oktaEmailCodeLabelKey = data?.HOME?.OktaEmailCodeLabel;
        this.oktaEmailCodeNotReceivedKey = data?.HOME?.OktaEmailCodeNotReceived;
        this.oktaEmailMfaDescKey = data?.HOME?.OktaEmailMfaDesc;
        this.oktaEmailMfaTitleKey = data?.HOME?.OktaEmailMfaTitle;
        this.oktaEmailResendKey = data?.HOME?.OktaEmailResend;
        this.oktaEmailSendKey = data?.HOME?.OktaEmailSend;
        this.oktaErrorConfigKey = data?.HOME?.OktaErrorConfig;
        this.oktaErrorEnabledCORSKey = data?.HOME?.OktaErrorEnabledCORS;
        this.oktaErrorExpiredSessionKey = data?.HOME?.OktaErrorExpiredSession;
        this.oktaErrorJITFailureKey = data?.HOME?.OktaErrorJITFailure;
        this.oktaErrorMfaExpiredSessionKey = data?.HOME?.OktaErrorMfaExpiredSession;
        this.oktaErrorMfaRequiredKey = data?.HOME?.OktaErrorMfaRequired;
        this.oktaErrorNetworkConnectionKey = data?.HOME?.OktaErrorNetworkConnection;
        this.oktaErrorOAuthIdTokenKey = data?.HOME?.OktaErrorOAuthIdToken;
        this.oktaErrorPasswordRequiredKey = data?.HOME?.OktaErrorPasswordRequired;
        this.oktaErrorUnsupportedBrowserKey = data?.HOME?.OktaErrorUnsupportedBrowser;
        this.oktaErrorUnsupportedCORSKey = data?.HOME?.OktaErrorUnsupportedCORS;
        this.oktaErrorUnsupportedLocalStorageKey = data?.HOME?.OktaErrorUnsupportedLocalStorage;
        this.oktaErrorUnsupportedResponseKey = data?.HOME?.OktaErrorUnsupportedResponse;
        this.oktaErrorUnsyncedClockKey = data?.HOME?.OktaErrorUnsyncedClock;
        this.oktaErrorUsernameRequiredKey = data?.HOME?.OktaErrorUsernameRequired;
        this.oktaErrorsE0000047Key = data?.HOME?.OktaErrorsE0000047;
        this.oktaErrorsE0000069Key = data?.HOME?.OktaErrorsE0000069;
        this.oktaErrorsE0000079Key = data?.HOME?.OktaErrorsE0000079;
        this.oktaErrorsEmailVerifyNotReceivedKey = data?.HOME?.OktaErrorsEmailVerifyNotReceived;
        this.oktaErrorsTitleKey = data?.HOME?.OktaErrorsTitle;
        this.oktaForgotPasswordKey = data?.HOME?.OktaForgotPassword;
        this.oktaGoBackKey = data?.HOME?.OktaGoBack;
        this.oktaHelpKey = data?.HOME?.OktaHelp;
        this.oktaMfaChallengeVerifyKey = data?.HOME?.OktaMfaChallengeVerify;
        this.oktaNeedHelpKey = data?.HOME?.OktaNeedHelp;
        this.oktaPasswordEmailSentKey = data?.HOME?.OktaPasswordEmailSent;
        this.oktaPasswordEmailSentDescKey = data?.HOME?.OktaPasswordEmailSentDesc;
        this.oktaPasswordPlaceholderKey = data?.HOME?.OktaPasswordPlaceholder;
        this.oktaPasswordResetKey = data?.HOME?.OktaPasswordReset;
        this.oktaPasswordTooltipKey = data?.HOME?.OktaPasswordTooltip;
        this.oktaRememberDeviceKey = data?.HOME?.OktaRememberDevice;
        this.oktaResetPasswordEmailKey = data?.HOME?.OktaResetPasswordEmail;
        this.oktaSubmitKey = data?.HOME?.OktaSubmit;
        this.oktaValidationFieldBlankKey = data?.HOME?.OktaValidationFieldBlank;
        this.oktaValidationFieldInvalidKey = data?.HOME?.OktaValidationFieldInvalid;
        this.oktaValidationFieldValueNotAllowedKey = data?.HOME?.OktaValidationFieldValueNotAllowed;
        this.oktaValidationFieldWrongTypeKey = data?.HOME?.OktaValidationFieldWrongType;
  
        this.oktaSignIn.remove();
        this.initOktaSignIn();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initOktaSignIn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.oktaSignIn?.remove();
  }

  private initOktaSignIn(): void {
    // Identify all Okta string properties that should be overridden with custom strings. List of properties:
    // https://github.com/okta/okta-signin-widget/blob/master/packages/%40okta/i18n/src/properties/login.properties
    let overriddenProperties = {
      'primaryauth.title': this.signInKey,
      'primaryauth.username.placeholder': this.emailKey,
      'primaryauth.username.tooltip': this.emailKey,
      'password.forgot.email.or.username.placeholder': this.emailKey,
      'password.forgot.email.or.username.tooltip': this.emailKey,
      'errors.E0000004': this.invalidEmailOrPasswordKey,
      'email.mfa.email.sent.description': this.multiFactorAuthenticationEmailSentKey,
      'error.auth.lockedOut': this.accountLockedKey,
      'error.username.required': this.oktaErrorUsernameRequiredKey
    };

    // Okta does not support some languages such as pt-pt, so we must translate the entire Okta Sign-In Widget ourselves
    let oktaUnsupportedLangsOverriddenProperties = {
      'primaryauth.title': this.signInKey,
      'primaryauth.username.placeholder': this.emailKey,
      'primaryauth.username.tooltip': this.emailKey,
      'password.forgot.email.or.username.placeholder': this.emailKey,
      'password.forgot.email.or.username.tooltip': this.emailKey,
      'errors.E0000004': this.invalidEmailOrPasswordKey,
      'email.mfa.email.sent.description': this.multiFactorAuthenticationEmailSentKey,
      'error.auth.lockedOut': this.accountLockedKey,
      //// begin unsupported language overrides for Okta Sign In Widget
      'email.button.resend': this.oktaEmailButtonResendKey,
      'email.code.label': this.oktaEmailCodeLabelKey,
      'email.code.not.received': this.oktaEmailCodeNotReceivedKey,
      'email.mfa.description': this.oktaEmailMfaDescKey,
      'email.mfa.title': this.oktaEmailMfaTitleKey,
      'email.button.send': this.oktaEmailSendKey,
      'error.config': this.oktaErrorConfigKey,
      'error.enabled.cors': this.oktaErrorEnabledCORSKey,
      'error.expired.session': this.oktaErrorExpiredSessionKey,
      'error.jit_failure': this.oktaErrorJITFailureKey,
      'error.mfa.only.expired.session': this.oktaErrorMfaExpiredSessionKey,
      'error.mfa.required': this.oktaErrorMfaRequiredKey,
      'error.network.connection': this.oktaErrorNetworkConnectionKey,
      'error.oauth.idToken': this.oktaErrorOAuthIdTokenKey,
      'error.password.required': this.oktaErrorPasswordRequiredKey,
      'error.unsupported.browser': this.oktaErrorUnsupportedBrowserKey,
      'error.unsupported.cors': this.oktaErrorUnsupportedCORSKey,
      'error.unsupported.localStorage': this.oktaErrorUnsupportedLocalStorageKey,
      'error.unsupported.response': this.oktaErrorUnsupportedResponseKey,
      'error.unsynced.clock': this.oktaErrorUnsyncedClockKey,
      'error.username.required': this.oktaErrorUsernameRequiredKey,
      'errors.E0000047': this.oktaErrorsE0000047Key,
      'errors.E0000069': this.oktaErrorsE0000069Key,
      'errors.E0000079': this.oktaErrorsE0000079Key,
      'oform.errorbanner.title': this.oktaErrorsTitleKey,
      'forgotpassword': this.oktaForgotPasswordKey,
      'goback': this.oktaGoBackKey,
      'help': this.oktaHelpKey,
      'mfa.challenge.verify': this.oktaMfaChallengeVerifyKey,
      'needhelp': this.oktaNeedHelpKey,
      'password.forgot.emailSent.title': this.oktaPasswordEmailSentKey,
      'password.forgot.emailSent.desc': this.oktaPasswordEmailSentDescKey,
      'primaryauth.password.placeholder': this.oktaPasswordPlaceholderKey,
      'password.reset': this.oktaPasswordResetKey,
      'primaryauth.password.tooltip': this.oktaPasswordTooltipKey,
      'rememberDevice.devicebased': this.oktaRememberDeviceKey,
      'password.forgot.sendEmail': this.oktaResetPasswordEmailKey,
      'primaryauth.submit': this.oktaSubmitKey,
      'model.validation.field.blank': this.oktaValidationFieldBlankKey,
      'model.validation.field.invalid': this.oktaValidationFieldInvalidKey,
      'model.validation.field.value.not.allowed': this.oktaValidationFieldValueNotAllowedKey,
      'model.validation.field.wrong.type': this.oktaValidationFieldWrongTypeKey
    }

    this.oktaSignIn = new this.OktaSignInWidget({
      baseUrl: environment.okta.baseUrl,
      redirectUri: environment.okta.redirectUri,
      clientId: environment.okta.clientId,
      authParams: {
        issuer: environment.okta.issuer,
        scopes: environment.okta.scopes,
      },
      i18n: {
        'en': overriddenProperties,
        'de': overriddenProperties,
        'fr': overriddenProperties,
        'es': overriddenProperties,
        'nl-NL': overriddenProperties,
        'nb': overriddenProperties,
        'pl': overriddenProperties,
        'fi': overriddenProperties,
        'sv': overriddenProperties,
        'pt-BR': overriddenProperties,
        'ja': overriddenProperties,
        'cs': overriddenProperties,
        'pt': oktaUnsupportedLangsOverriddenProperties
      },
      logo: '',
      language: this.getOktaLang(),
      // Changes to widget functionality
      features: {
        registration: false, // Enable self-service registration flow
        rememberMe: false, // Setting to false will remove the checkbox to save username
        router: false, // Leave this set to true for the API demo
        scrollOnError: false,
      },
      helpLinks: {
        help: `${environment.okta.redirectUri}/support/content?categoryId=myairaccount&subcategoryId=login`,
      },
    });

    // Automatically click the "Send me the code" button during the MFA flow to improve the UX.
    // Ideally the Okta widget would have a feature to enable this functionality, but it doesn't
    // (see https://support.okta.com/help/s/case/5004z00001cplr9AAA/auto-send-mfa-verification-code-from-sign-in-widget?language=en_US)
    //
    // This work was done as part of MWEB-825.
    //
    this.oktaSignIn.on('afterRender', function (context) {
      if (context.controller === 'mfa-verify') {
        let sendCodeButton : HTMLInputElement = document.querySelector('form.mfa-verify-email input[type="submit"]');
        if (sendCodeButton !== null) {
          sendCodeButton.click();
        }
      }
    });

    this.oktaSignIn.on('afterError', () => {
      const errorBlock = document.querySelector('.o-form-error-container');
      const pixelsToScroll = errorBlock.getBoundingClientRect().top + window.scrollY;

      this.window.scrollTo({
        behavior: 'smooth',
        top: pixelsToScroll,
      });
    });

    this.oktaSignIn.authClient.token.getUserInfo().then(
      (user) => {},
      (error) => {
        this.oktaSignIn
          .showSignInToGetTokens({
            el: '#okta-login-container',
          })
          .then(async (tokens) => {
            // Diplay a loading spinner to cover all of this asynchronous activity
            const spinner = this.spinnerService.show();
            this.oktaSignIn.authClient.tokenManager.setTokens(tokens);
            this.oktaSignIn.remove();
            await this.authService.login(tokens);

            // Login is successful, so initialize the Pinpoint service
            try {
              const countryId = this.metadataService.getSelectedCountryId();
              const isSmartCoachingSupportedInCountry = await this.pinpointService.isSmartCoachingSupportedInCountry(countryId);
              
              if(isSmartCoachingSupportedInCountry) {
                const isSmartCoachingEnabledForPatient = await this.pinpointService.isSmartCoachingEnabledForPatient();
                const pinpointFlag = await this.pinpointService.pinpointFlag;
                
                if(isSmartCoachingEnabledForPatient && pinpointFlag) {
                  await this.authService.getAWSCredentials(tokens.idToken);
                  const subscriberId = tokens.idToken.claims.sub;
                  // Update the PinpointService instance with new credentials
                  this.pinpointService.updateEndpoint(subscriberId, "_session.start");
                }
              }
            } catch (error) {
              this.pinpointService.showErrorAsync(error, { disableAlert: true });
              // console.error('Failed to get AWS credentials for Pinpoint', error);
            }

            this.analyticsService.logLoggedInEvent();
            this.localStorage.setItem(
              this.lastLoggedInKey,
              this.authService.authenticatedUser$.value.Email,
            );
            // Hide the loading spinner upon completion 
            spinner.hide();
            const metadataQuerySwapRequired = await this.metadataService.checkIfMetadataQuerySwapRequired();
            if (metadataQuerySwapRequired) {
              this.metadataService.attemptedToLoadApiMetadata = false;
            }
            this.metadataService.attemptedToLoadMaskMetadata = false;
            this.router.navigate(['dashboard']);

          })
          .catch((err) => {
            if (err instanceof RegisterApplicationError) {
              this.localStorage.setItem(this.logoutAlertKey, err.message);
              this.authService.logout();
            }
          });
      },
    );
  }

// Okta language support doesn't like lowercase countries if a country is included in the language code. Swap these for uppercase to match Okta's supported language codes
// For a list of language codes Okta Sign In Widget supports: https://github.com/okta/okta-signin-widget#supported-languages
  getOktaLang(): string {
    const code = this.translateService.currentLang;
    switch (code) {
      case 'nl-nl':
        return 'nl-NL';
      case 'pt-br':
        return 'pt-BR';
      case 'zh-tw':
        return 'zh-TW';
      default:
        return code.slice(0, 2);
    }
  }
}
