<div class="u-margin-bottom-large">

  <div class="js-acc-panel is-active" style="display:block;">

    <div class="c-acc-panel c-acc-panel--header">
      <div class="c-acc-panel__item">
        <div class="c-acc-panel__heading">
          <h2 id="aboutme" class="c-heading-acc">{{ 'MY-ACCOUNT/ABOUT-ME.AboutMe' | translate}}</h2>
        </div>
        <div class="c-acc-panel__btn" *ngIf="aboutMeStateValue === aboutMeState.View">
          <a class="c-link-ui" (click)="aboutMeStateValue = aboutMeState.Edit">{{ 'MY-ACCOUNT.Change' | translate}}</a>
        </div>
      </div>
    </div>

    <mpp-about-me-view
      *ngIf="aboutMeStateValue === aboutMeState.View"
      [timeZone]="currentTimezone"
      [timeZoneId]="currentTimezoneId"
      [aboutMeDetails]="aboutMeDetails"
      [isJapanese]="isJapanese"
      (stateChanged)="aboutMeStateValue = $event"></mpp-about-me-view>
    <mpp-about-me-edit
      *ngIf="aboutMeStateValue === aboutMeState.Edit"
      [allTimezones]="allTimezones"
      [aboutMeDetails]="aboutMeDetails"
      [isJapanese]="isJapanese"
      (stateChanged)="aboutMeStateValue = $event"
      (aboutMeChanged)="onAboutMeChange($event)"></mpp-about-me-edit>
  </div>

</div>
