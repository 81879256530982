import { Component } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PtaService } from 'src/app/services/pta.service';
import { PtaStepperComponent } from '../pta-stepper/pta-stepper.component';

@Component({
  selector: 'mpp-pta-prompt-modal',
  templateUrl: './pta-prompt-modal.component.html',
  styleUrls: ['./pta-prompt-modal.component.scss']
})
export class PtaPromptModalComponent {
  constructor(
    private dialogRef: MatLegacyDialogRef<PtaPromptModalComponent>,
    private dialog: MatLegacyDialog,
    private pta: PtaService) {}

  closeModal(displayLibraryModal: boolean = true): void {
    // Choose to display the Library Modal or not
    if (displayLibraryModal) {
      this.dialogRef.close();
    } else {
      this.pta.doNotLaunchLibraryModal = true;
      this.dialogRef.close();
    }
    
  }

  openPTA(): void {
    // Open PTA Modal but do not launch Library modal in this path
    this.closeModal(false);
    this.dialog.open(PtaStepperComponent, { panelClass: 'pta-dialog'}).beforeClosed().subscribe(() => {
      this.pta.doNotLaunchLibraryModal = false; this.pta.attemptToLaunchLibraryModal();
    });
  }
}
