import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LanguageId } from 'src/app/types/language.type';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@auth/auth.service';
import { CountryService } from 'src/app/services/country/country.service';
import { CountryData } from 'src/app/types/country.type';
import { BaseComponent } from '../base/component/base-component';
import { LegalDisclaimerComponent } from '../dialogs/legal-disclaimer/legal-disclaimer.component';

@Component({
  selector: 'mpp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  translateParams = { currentYear: new Date().getFullYear() };
  selectedLanguageId: LanguageId;
  selectedCountry: CountryData;
  isAuthenticationSet = false;
  isAuthenticated = false;
  showLegalDisclaimer: boolean = false;
  isNotSupportedCountry: boolean = true;

  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private authService: AuthService,
    private countryService: CountryService,
    private dialog: MatDialog,
  ) {
    super();
  }
  ngOnInit(): void {
    this.languageService.selectedLanguageId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((languageId) => {
        this.selectedLanguageId = languageId;
        this.checkToDisplayLegalDisclaimer();
      });

    this.countryService.selectedCountryId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.selectedCountry = this.countryService.getSelectedCountry();
    });

    this.authService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated !== undefined) {
          this.isAuthenticationSet = true;
        }
        this.isAuthenticated = isAuthenticated;
      });
    this.checkToDisplayLegalDisclaimer();

    this.countryService.loadSupportedCountries().subscribe(res => this.isNotSupportedCountry = !res.includes(this.countryService.getSelectedCountryId()));
  }

  getLanguageIds(): LanguageId[] {
    return [...this.selectedCountry.languages];
  }

  selectLanguage(languageId: LanguageId): void {
    this.languageService.selectLanguage(languageId);
  }
  
  openLegalDisclaimer(): void {
    this.dialog.open(LegalDisclaimerComponent);
  }

  checkToDisplayLegalDisclaimer(): void {
    // First check to see if we have one of the countries that has disclaimers
    switch(this.countryService.getSelectedCountryId()) {
      // Then if we have selected one of those countries, check to see if they are on a supported language
      case 'AT':
        this.translate.get('FOOTER.LegalDisclaimerAustria').pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.showLegalDisclaimer = !!res);
        break;
      case 'DE':
        this.translate.get('FOOTER.LegalDisclaimerGermany').pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.showLegalDisclaimer = !!res);
        break;
      case 'CH':
        this.translate.get('FOOTER.LegalDisclaimerSwitzerland').pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.showLegalDisclaimer = !!res);
        break;
      default:
        this.showLegalDisclaimer = false;
        break;
    }
  }
}
