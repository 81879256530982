<section class="o-section o-section--countries">
  <div *ngIf="countries$" class="o-wrapper">

    <h2 class="c-heading-location">{{'COUNTRY-SELECTION.ChooseYourLocation' | translate}}</h2>

    <div class="o-layout  [ ani-hideFadein@tablet u-hide-below-tablet ]">

      <div class="o-layout__item  [ u-1/3@tablet ]">
        <h3 class="c-heading-region">{{'COUNTRY-SELECTION.Americas' | translate}}</h3>
      </div>

      <div class="o-layout__item  [ u-1/3@tablet ]">
        <h3 class="c-heading-region">{{'COUNTRY-SELECTION.EuropeMiddleEastAndAfrica' | translate}}</h3>
      </div>

      <div class="o-layout__item  [ u-1/3@tablet ]">
        <h3 class="c-heading-region">{{'COUNTRY-SELECTION.AsiaPacific' | translate}}</h3>
      </div>

    </div>


    <div class="o-layout ani-hideFadein@tablet">

      <div id="americas" class="o-layout__item o-section--countries__item  [ u-1/3@tablet ]">

        <div (transitionend)="transitionend(0, $event)" (click)="toggleExpander(0)" class="c-expander c-expander--countries c-expander@mobile c-expander--group"
          data-group="countries-group" [attr.data-state]="dataState[0]">
          <h3 class="c-expander__label u-show-below-tablet" [attr.aria-expanded]="ariaExpanded[0]" [attr.aria-controls]="'region-AMR'" role="button">
            <svg class="c-svg c-svg--chevron-down-24">
              <use xlink:href="#c-svg--chevron-down-24"></use>
            </svg>{{'COUNTRY-SELECTION.Americas' | translate}}
          </h3>
          <div id="region-AMR" class="c-expander__panel">

            <div class="o-layout o-layout--small">
              <div class="o-layout__item  u-1/2@desktop">

                <ul class="c-list c-list--countries u-margin-bottom">
                  <li class="c-list__item">
                    <a [title]="countries$.CA.name | translate" class="c-list__link" (click)="selectCountry(countries$.CA.id)">
                      <span [ngClass]="countries$.CA.flagClass" data-country="Canada">{{countries$.CA.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.US.name | translate" class="c-list__link" (click)="selectCountry(countries$.US.id)">
                      <span [ngClass]="countries$.US.flagClass" data-country="United States">{{countries$.US.name | translate}}</span>
                    </a>
                  </li>
                </ul>

              </div>
              <div class="o-layout__item  u-1/2@desktop">

                <ul class="c-list c-list--countries u-margin-bottom">
                  <li class="c-list__item">
                    <a [title]="countries$.AR.name | translate" class="c-list__link" (click)="selectCountry(countries$.AR.id)">
                      <span [ngClass]="countries$.AR.flagClass">{{countries$.AR.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.BR.name | translate" class="c-list__link" (click)="selectCountry(countries$.BR.id)">
                      <span [ngClass]="countries$.BR.flagClass">{{countries$.BR.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.CL.name | translate" class="c-list__link" (click)="selectCountry(countries$.CL.id)">
                      <span [ngClass]="countries$.CL.flagClass">{{countries$.CL.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.CO.name | translate" class="c-list__link" (click)="selectCountry(countries$.CO.id)">
                      <span [ngClass]="countries$.CO.flagClass">{{countries$.CO.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.MX.name | translate" class="c-list__link" (click)="selectCountry(countries$.MX.id)">
                      <span [ngClass]="countries$.MX.flagClass">{{countries$.MX.name | translate}}</span>
                    </a>
                  </li>
                </ul>

              </div>
            </div>

          </div>

        </div>

      </div>

      <div id="europe" class="o-layout__item o-section--countries__item  [ u-1/3@tablet ]">

        <div (transitionend)="transitionend(1, $event)" (click)="toggleExpander(1)" class="c-expander c-expander--countries c-expander@mobile c-expander--group"
          data-group="countries-group" [attr.data-state]="dataState[1]">
          <h3 class="c-expander__label  u-show-below-tablet" [attr.aria-expanded]="ariaExpanded[1]" [attr.aria-controls]="'region-EU'" role="button">
            <svg class="c-svg c-svg--chevron-down-24">
              <use xlink:href="#c-svg--chevron-down-24"></use>
            </svg>{{'COUNTRY-SELECTION.EuropeMiddleEastAndAfrica' | translate}}
          </h3>
          <div id="region-EU" class="c-expander__panel c-expander__panel--max">

            <div class="o-layout o-layout--small">
              <div class="o-layout__item  u-1/2@desktop">

                <ul class="c-list c-list--countries">
                  <li class="c-list__item">
                    <a [title]="countries$.AT.name | translate" class="c-list__link" (click)="selectCountry(countries$.AT.id)">
                      <span [ngClass]="countries$.AT.flagClass" data-country="Austria">{{countries$.AT.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.BE.name | translate" class="c-list__link" (click)="selectCountry(countries$.BE.id)">
                      <span [ngClass]="countries$.BE.flagClass" data-country="Belgium">{{countries$.BE.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.CY.name | translate" class="c-list__link" (click)="selectCountry(countries$.CY.id)">
                      <span [ngClass]="countries$.CY.flagClass" data-country="Cyprus">{{countries$.CY.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.CZ.name | translate" class="c-list__link" (click)="selectCountry(countries$.CZ.id)">
                      <span [ngClass]="countries$.CZ.flagClass" data-country="Czech Republic">{{countries$.CZ.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.DK.name | translate" class="c-list__link" (click)="selectCountry(countries$.DK.id)">
                      <span [ngClass]="countries$.DK.flagClass" data-country="Denmark">{{countries$.DK.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.FI.name | translate" class="c-list__link" (click)="selectCountry(countries$.FI.id)">
                      <span [ngClass]="countries$.FI.flagClass" data-country="Finland">{{countries$.FI.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.FR.name | translate" class="c-list__link" (click)="selectCountry(countries$.FR.id)">
                      <span [ngClass]="countries$.FR.flagClass" data-country="France">{{countries$.FR.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.DE.name | translate" class="c-list__link" (click)="selectCountry(countries$.DE.id)">
                      <span [ngClass]="countries$.DE.flagClass" data-country="Germany">{{countries$.DE.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.GR.name | translate" class="c-list__link" (click)="selectCountry(countries$.GR.id)">
                      <span [ngClass]="countries$.GR.flagClass" data-country="Greece">{{countries$.GR.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.IS.name | translate" class="c-list__link" (click)="selectCountry(countries$.IS.id)">
                      <span [ngClass]="countries$.IS.flagClass" data-country="Iceland">{{countries$.IS.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.IE.name | translate" class="c-list__link" (click)="selectCountry(countries$.IE.id)">
                      <span [ngClass]="countries$.IE.flagClass" data-country="Ireland">{{countries$.IE.name | translate}}</span>
                    </a>
                  </li>
                </ul>

              </div>
              <div class="o-layout__item  u-1/2@desktop">
                <ul class="c-list c-list--countries">
                  <li class="c-list__item">
                    <a [title]="countries$.IT.name | translate" class="c-list__link" (click)="selectCountry(countries$.IT.id)">
                      <span [ngClass]="countries$.IT.flagClass" data-country="Italy">{{countries$.IT.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.LU.name | translate" class="c-list__link" (click)="selectCountry(countries$.LU.id)">
                      <span [ngClass]="countries$.LU.flagClass" data-country="Luxembourg">{{countries$.LU.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.MT.name | translate" class="c-list__link" (click)="selectCountry(countries$.MT.id)">
                      <span [ngClass]="countries$.MT.flagClass" data-country="Malta">{{countries$.MT.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.NL.name | translate" class="c-list__link" (click)="selectCountry(countries$.NL.id)">
                      <span [ngClass]="countries$.NL.flagClass" data-country="Netherlands">{{countries$.NL.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.NO.name | translate" class="c-list__link" (click)="selectCountry(countries$.NO.id)">
                      <span [ngClass]="countries$.NO.flagClass" data-country="Norway">{{countries$.NO.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.PL.name | translate" class="c-list__link" (click)="selectCountry(countries$.PL.id)">
                      <span [class]="countries$.PL.flagClass" data-country="Poland">{{countries$.PL.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.PT.name | translate" class="c-list__link" (click)="selectCountry(countries$.PT.id)">
                      <span [ngClass]="countries$.PT.flagClass" data-country="Portugal">{{countries$.PT.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.ZA.name | translate" class="c-list__link" (click)="selectCountry(countries$.ZA.id)">
                      <span [ngClass]="countries$.ZA.flagClass" data-country="South Africa">{{countries$.ZA.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.ES.name | translate" class="c-list__link" (click)="selectCountry(countries$.ES.id)">
                      <span [ngClass]="countries$.ES.flagClass" data-country="Spain">{{countries$.ES.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.SE.name | translate" class="c-list__link" (click)="selectCountry(countries$.SE.id)">
                      <span [ngClass]="countries$.SE.flagClass" data-country="Sweden">{{countries$.SE.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.CH.name | translate" class="c-list__link" (click)="selectCountry(countries$.CH.id)">
                      <span [ngClass]="countries$.CH.flagClass" data-country="Switzerland">{{countries$.CH.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.GB.name | translate" class="c-list__link" (click)="selectCountry(countries$.GB.id)">
                      <span [ngClass]="countries$.GB.flagClass" data-country="United Kingdom">{{countries$.GB.name | translate}}</span>
                    </a>
                  </li>
                </ul>

              </div>
            </div>

          </div>
        </div>

      </div>

      <div id="apac" class="o-layout__item o-section--countries__item  [ u-1/3@tablet ]">

        <div (transitionend)="transitionend(2, $event)" (click)="toggleExpander(2)" class="c-expander c-expander--countries c-expander@mobile c-expander--group"
          data-group="countries-group" [attr.data-state]="dataState[2]">
          <h3 class="c-expander__label  u-show-below-tablet" [attr.aria-expanded]="ariaExpanded[2]" [attr.aria-controls]="'region-APAC'" role="button">
            <svg class="c-svg c-svg--chevron-down-24">
              <use xlink:href="#c-svg--chevron-down-24"></use>
            </svg>{{'COUNTRY-SELECTION.AsiaPacific' | translate}}
          </h3>
          <div id="region-APAC" class="c-expander__panel  c-expander__panel--600">
            <div class="o-layout o-layout--small">
              <div class="o-layout__item  u-1/2@desktop">
                <ul class="c-list c-list--countries">
                  <li class="c-list__item">
                    <a [title]="countries$.AU.name| translate" class="c-list__link" (click)="selectCountry(countries$.AU.id)">
                      <span [ngClass]="countries$.AU.flagClass" data-country="Australia">{{countries$.AU.name| translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.HK.name | translate" class="c-list__link" (click)="selectCountry(countries$.HK.id)">
                      <span [ngClass]="countries$.HK.flagClass" data-country="Hong Kong">{{countries$.HK.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.IN.name | translate" class="c-list__link" (click)="selectCountry(countries$.IN.id)">
                      <span [ngClass]="countries$.IN.flagClass" data-country="India">{{countries$.IN.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.JP.name | translate" class="c-list__link" (click)="selectCountry(countries$.JP.id)">
                      <span [ngClass]="countries$.JP.flagClass" data-country="Japan">{{countries$.JP.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.MY.name | translate" class="c-list__link" (click)="selectCountry(countries$.MY.id)">
                      <span [ngClass]="countries$.MY.flagClass" data-country="Malaysia">{{countries$.MY.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.NZ.name | translate" class="c-list__link" (click)="selectCountry(countries$.NZ.id)">
                      <span [ngClass]="countries$.NZ.flagClass" data-country="New Zealand">{{countries$.NZ.name | translate}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="o-layout__item  u-1/2@desktop">
                <ul class="c-list c-list--countries">
                  <li class="c-list__item">
                    <a [title]="countries$.PH.name | translate" class="c-list__link" (click)="selectCountry(countries$.PH.id)">
                      <span [ngClass]="countries$.PH.flagClass" data-country="Philippines">{{countries$.PH.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.SG.name | translate" class="c-list__link" (click)="selectCountry(countries$.SG.id)">
                      <span [ngClass]="countries$.SG.flagClass" data-country="Singapore">{{countries$.SG.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.KR.name | translate" class="c-list__link" (click)="selectCountry(countries$.KR.id)">
                      <span [ngClass]="countries$.KR.flagClass" data-country="South Korea">{{countries$.KR.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.TW.name | translate" class="c-list__link" (click)="selectCountry(countries$.TW.id)">
                      <span [ngClass]="countries$.TW.flagClass" data-country="Taiwan">{{countries$.TW.name | translate}}</span>
                    </a>
                  </li>
                  <li class="c-list__item">
                    <a [title]="countries$.TH.name | translate" class="c-list__link" (click)="selectCountry(countries$.TH.id)">
                      <span [ngClass]="countries$.TH.flagClass" data-country="Thailand">{{countries$.TH.name | translate}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</section>