<!-- #region Date of Birth -->
<form [formGroup]="form">
<div class="c-form-field u-margin-top-large">
  <label class="c-form-field__label">
    {{"MY-ACCOUNT/ABOUT-ME.DateOfBirth" | translate}}
  </label>

  <div class="c-form-field__input c-input--validation" [attr.data-form-field-status]="form.invalid && form.touched ? 'error':
                                                                                      form.valid && form.touched ? 'completed' : 'incompleted'">
    <div class="c-input-group c-input-group--dob">
      <div class="c-input-group__item c-input--dob-day">
        <label for="dob-day" class="u-hidden">{{'MY-ACCOUNT/ABOUT-ME.Day' | translate}}</label>
        <div class="c-input c-input--select">
          <select id="dob-day" class="dob-day" formControlName="day">
            <option value="">{{"MY-ACCOUNT/ABOUT-ME.Day" | translate}}</option>
            <option *ngFor="let day of daysArray" [ngValue]="day">{{ day }}</option>
          </select>
        </div>
      </div>

      <div class="c-input-group__item c-input--dob-month">
        <label for="dob-month" class="u-hidden">{{'MY-ACCOUNT/ABOUT-ME.Month' | translate}}</label>
        <div class="c-input c-input--select">
          <select id="dob-month" class="dob-month" formControlName="month">
            <option value="">{{"MY-ACCOUNT/ABOUT-ME.Month" | translate}}</option>
            <option *ngFor="let monthId of getMonthIds()" [ngValue]="monthId">{{
                            getMonthName(monthId) | translate
                          }}</option>
          </select>
        </div>
      </div>

      <div class="c-input-group__item c-input--dob-year">
        <label for="dob-year" class="u-hidden">{{'MY-ACCOUNT/ABOUT-ME.Year' | translate}}</label>
        <input id="dob-year" formControlName="year" type="tel" [placeholder]="'MY-ACCOUNT/ABOUT-ME.Year' | translate" pattern="[0-9]*"
          type="text" maxlength="4" class="c-input dob-year" />
      </div>
    </div>
  </div>

  <div *ngIf="form.touched && form.invalid">
    <div *ngIf="form.errors?.dateIsInvalid &&
                            !isBirthdayControlRequired()" class="c-form-field__error" role="alert" aria-live="assertive">
      {{"ERRORS.InvalidDateOfBirth" | translate}}</div>

    <div *ngIf="form.errors?.youngerThanMinimumAge &&
                          !isBirthdayControlRequired()" [innerHTML]="'ERRORS.AgeLessThanMinimalAge' | translate: {age: minimumAge}"
      class="c-form-field__error" role="alert" aria-live="assertive"></div>

    <div *ngIf="form.errors?.dateInTheFuture &&
                          !isBirthdayControlRequired()" class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.DateInTheFuture" | translate}}
    </div>

    <div *ngIf="form.errors?.invalidYear &&
                          !isBirthdayControlRequired()" class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.InvalidYear" | translate}}
    </div>
  </div>

  <div *ngIf="isBirthdayControlRequired()" class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</div>

  <div class="c-form-field__hint">
    {{"MY-ACCOUNT/ABOUT-ME.YearTip" | translate}}
  </div>
</div>
</form>
  <!-- #endregion Date of Birth -->
