import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(public authService: AuthService, public router: Router) {}
  canActivateChild(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }
  canLoad(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }
  async canActivate(): Promise<boolean | UrlTree> {
    if (await this.authService.checkAuthenticated()) {
      return true;
    }
    return this.router.createUrlTree(['/']);
  }
}
