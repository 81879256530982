import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryService } from 'src/app/services/country/country.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { BaseComponent } from '../../base/component/base-component';

@Component({
  selector: 'mpp-feature-description',
  templateUrl: './feature-description.component.html',
  styleUrls: ['./feature-description.component.scss'],
})
export class FeatureDescriptionComponent extends BaseComponent implements OnInit {
  maxPoints = 100;
  showMobileAppsBanner = true;

  constructor(public countryService: CountryService, public languageService: LanguageService, private analytics: AnalyticsService) {
    super();
  }

  ngOnInit(): void {
    // get and set appStoreLink$ and playStoreLink$
    this.countryService.initializeMobileStoreLinks();
    
    if (this.countryService.latAmNonRollout()) {
      this.showMobileAppsBanner = false;
    } else {
      this.showMobileAppsBanner = true;
    }
  }

  playStoreLinkClicked(): void {
    this.analytics.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analytics.redirectLocationFeatureDescription);
  }

  appStoreLinkClicked(): void {
    this.analytics.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analytics.redirectLocationFeatureDescription);
  }
}
