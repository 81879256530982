import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Mask } from 'src/app/services/patient/types/device.type';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { MyMaskReturningPath } from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/types/mask-return-path.enum';
import { MaskMagnetsComponent } from 'src/app/components/dialogs/mask-magnets/mask-magnets.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { Apollo } from 'apollo-angular';
import { MaskService } from './mask.service';

@Component({
  selector: 'mpp-my-account-mask',
  templateUrl: './my-account-mask.component.html',
  styleUrls: ['./my-account-mask.component.scss'],
})
export class MyAccountMaskComponent extends BaseComponent implements OnInit {
  public currentMask: Mask;
  private showMaskMagneticEducationalMaterials: boolean = false;
  return: MyMaskReturningPath;
  maskFormGroup = this.formBuilder.group({
    maskType: ['', Validators.required],
    maskManufacturer: ['', Validators.required],
    maskModel: ['', Validators.required],
    hasMagnets: ['', Validators.required]
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private metadataService: MetadataService,
    private client: Apollo,
    private maskService: MaskService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
      this.return = params.return;
    });
    this.metadataService.getMetadataFromApi().subscribe(metadata => {
      this.showMaskMagneticEducationalMaterials = metadata.countries.find(country => country.code === this.metadataService.getSelectedCountryId())?.showMaskMagneticEducationalMaterials;
    });

    await this.setCurrentMask();
  }

  getRouteBack(): string[] {
    if (Object.values(MyMaskReturningPath).includes(this.return)) {
      return [this.return];
    }
    // redirect to my-account if return query isn't correct or present
    return [MyMaskReturningPath.myaccount];
  }

  goBack(): Promise<boolean> {
    return this.router.navigate(this.getRouteBack());
  }

  private async setCurrentMask(): Promise<void> {
    const spinner = this.spinnerService.show();
    const [masks] = await this.patientService.getMask().toPromise();
    if (masks) {
      [this.currentMask] = masks;
      this.maskFormGroup.get('maskType').setValue(this.currentMask.maskType);
      this.maskFormGroup.get('maskManufacturer').setValue(this.currentMask.maskManufacturerName);
      this.maskFormGroup.get('maskModel').setValue(this.currentMask.maskCode);
    }
    spinner.hide();
  }

  public cancelMaskChanges(): void {
    this.goBack();
  }

  public updatePatientMask(): void {
    const spinner = this.spinnerService.show();
    if (this.maskFormGroup.get('hasMagnets').value && this.showMaskMagneticEducationalMaterials) {
      this.dialog.open(MaskMagnetsComponent);
    }
    this.patientService
      .removeMask({
        maskManufacturerName: this.currentMask.maskManufacturerName,
        maskCode: this.currentMask.maskCode,
      })
      .subscribe(([error]) => {
        if (error) {
          const errorCode = error.errorInfo?.errorCode as string;
          this.maskFormGroup.setErrors({ [errorCode]: true });
          spinner.hide();
        } else {
          this.addMask().then(() => {
            // Submit all present mask answers from the form group
            let index = 0;
            while(this.maskFormGroup.get('maskQuestion' + index)) {
              this.maskService.submitMaskAnswers({ data: [{ questionId: this.maskService.maskQuestions[index].questionId, answerId: this.maskFormGroup.get('maskQuestion' + index).value }]}).subscribe();
              index++;
            }
            spinner.hide();
            // Clear the cache so the mask actually updates on the dashboard
           this.client.client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPatientWrapper', });
           this.client.client.cache.gc();
           this.goBack();
          });
        }
      });
  }

  private addMask(): Promise<void> {
    return this.patientService
      .addMask({
        maskManufacturerName: this.maskFormGroup.get('maskManufacturer').value,
        maskCode: this.maskFormGroup.get('maskModel').value,
      })
      .pipe(
        map(([error]) => {
          if (error) {
            const errorCode = error.errorInfo?.errorCode as string;
            this.maskFormGroup.setErrors({ [errorCode]: true });
          }
        }),
      )
      .toPromise();
  }
}
