import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, switchMap, take, tap } from 'rxjs/operators';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { environment } from 'src/environments/environment';
import { CountryId } from 'src/app/types/country.type';
import { LocalStorageService } from '../storage/storage.service';
import { RootObject } from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/models/mask.model';
import { LanguageId, languages2Letters } from 'src/app/types/language.type';
@Injectable({
  providedIn: 'root'
})
export class MetadataService extends ShowTimeApiService{
  private readonly CountryIdKey = 'CurrentMyAirCountryId';
  private readonly LanguageIdKey = 'CurrentMyAirLanguageId';
  private readonly consumingVariantKey = 'ConsumingVariantKey';
  public attemptedToLoadLocalMetadata: boolean = false; // Start off false as we haven't attempted to load it
  public attemptedToLoadApiMetadata: boolean = false; // Start off false as we haven't attempted to load it
  public attemptedToLoadMaskMetadata: boolean = false; // Start off false as we haven't attempted to load it
  private metadataLocalSubject: ReplaySubject<any> = new ReplaySubject(1);
  private metadataApiSubject: ReplaySubject<any> = new ReplaySubject(1);
  private maskMetadataSubject: ReplaySubject<any> = new ReplaySubject(1);
  private lastCountryMetadataQueried: CountryId = null;

  constructor(
    private http: HttpClient,
    protected alertService: AlertService,
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {
      super(alertService);
  }

  public getMetadataFromLocal(nestedKey: string): Observable<any> {  
    return this.metadataLocalSubject.pipe(
      take(1),
      map(data => data && data[nestedKey] ? data[nestedKey] : null),
      tap(data => {
        if (!data) {
          this.showError();
        }
      }),
    );
  }

  public getMetadataFromApi(): Observable<any> {  
    return this.metadataApiSubject.pipe(
      take(1),
      tap(data => {
        if (!data) {
          this.showError();
        }
      }),
    );
  }

  public getMaskMetadata(): Observable<any> {  
    return this.maskMetadataSubject.pipe(
      take(1),
      tap(data => {
        if (!data) {
          this.showError();
        }
      }),
    );
  }

  public checkIfMetadataLocalLoadNecessary(): void {
    if (!this.attemptedToLoadLocalMetadata) {
      this.attemptedToLoadLocalMetadata = true;  
      this.updateMetadataFromLocal(environment.endpoints.localMetadataMyAir,'myAir_Web');
    }
  }

  public checkIfMetadataApiLoadNecessary(): void {
    if (!this.attemptedToLoadApiMetadata) {
      this.attemptedToLoadApiMetadata = true;  
      this.updateMetadataFromApi();
    }
  }

  public checkIfMaskMetadataLoadNecessary(): void {
    if (!this.attemptedToLoadMaskMetadata) {
      this.attemptedToLoadMaskMetadata = true;  
      this.updateMaskMetadata();
    }
  }

  private updateMetadataFromLocal(url: string, platform: string): void {
    this.http.get(url).pipe(
      tap(
        (data) => {
          this.metadataLocalSubject.next(data[platform]);
        }
      ),
    ).subscribe(); // Since updateMetadataFromLocal() is expected to complete or error out, no explicit unsubscription is strictly necessary here.
  }

  private updateMetadataFromApi(): void {
    this.getMetadata().subscribe(data => {
      this.metadataApiSubject.next(data);
      this.localStorage.setItem(this.consumingVariantKey, !!data?.instanceInfo?.deprecation?.consumingVariant ? data?.instanceInfo?.deprecation?.consumingVariant : ''); // This may not necessarily exist but if it does we should use it
    });
  } // Since updateMetadataFromApi() is expected to complete or error out, no explicit unsubscription is strictly necessary here.

  private updateMaskMetadata(): void {
    this.getMasksList().subscribe(data => {
      this.maskMetadataSubject.next(data);
    }, 
    this.showError());
  }

  public getSelectedCountryId(): CountryId {
    return this.localStorage.getItem(this.CountryIdKey) as CountryId;
  }

  public async checkIfMetadataQuerySwapRequired(): Promise<boolean> {
    if (this.lastCountryMetadataQueried === this.getSelectedCountryId()) {
      return new Promise((resolve, reject) => { resolve(false); });
    }
    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries()
    ]).pipe(
      first(),
      switchMap(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES]) => {
        if (US_AU_NZCountries.includes(this.getSelectedCountryId()) && US_AU_NZCountries.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (LATAM_COUNTRIES.includes(this.getSelectedCountryId()) && LATAM_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (this.getSelectedCountryId() === 'CA' && this.lastCountryMetadataQueried === 'CA') {
          return of(false);
        } else if (EU_COUNTRIES.includes(this.getSelectedCountryId()) && EU_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (APAC_COUNTRIES.includes(this.getSelectedCountryId()) && APAC_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else {
          return of(true);
        }
      })).toPromise();
  }

  private getMetadata(): Observable<any> {
    let queryString: string;

    // To prevent excessive calls, set the country being queried, to be checked later
    this.lastCountryMetadataQueried = this.getSelectedCountryId();

    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries()
    ]).pipe(
      first(),
      switchMap(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES]) => {
        if (US_AU_NZCountries.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir';
        } else if (LATAM_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20LATAM';
        } else if (this.getSelectedCountryId() === 'CA') {
          queryString = 'product=myAir%20Canada';
        } else if (EU_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20EU';
        } else if (APAC_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20APAC';
        } else {
          throw new Error('Invalid Country');
        }
    
        return this.httpClient.get(
          `${environment.endpoints.staticMyair}/v2/metadata.json?platform=Web&locale=en&${queryString}`
        );
      }),
      this.showError()
    );
  }

  private loadUS_AU_NZCountries(): Observable<any> {
    return this.getMetadataFromLocal('US_AU_NZ_COUNTRIES');
  }

  private loadEUCountries(): Observable<any> {
    return this.getMetadataFromLocal('EU_COUNTRIES');
  }

  private loadAPACCountries(): Observable<any> {
    return this.getMetadataFromLocal('APAC_COUNTRIES');
  }

  private loadLATAMCountries(): Observable<any> {
    return this.getMetadataFromLocal('LATAM_COUNTRIES');
  }

  private getMasksList(): Observable<RootObject> {
    const params = new HttpParams()
      .set('countryId', this.getSelectedCountryId() || 'US')
      .set('locale', languages2Letters[this.localStorage.getItem(this.LanguageIdKey) as LanguageId]);
  
    return this.http.get<RootObject>(
      `${environment.endpoints.staticMyair}/v2/masks/mask_assets.json`,
      { params, responseType: 'json' }
    ).pipe(
      catchError(() => {
        this.showError();
        return of(null);
      })
    );
  }
  
}
