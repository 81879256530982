import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard/auth.guard';
import { HomeGuard } from 'src/app/services/home-guard/home.guard';
import { PoliciesRoutePaths } from 'src/app/modules/policies/policies-route-paths';
import { HomeComponent } from './components/home/home.component';
import { CountrySelectionComponent } from './components/country-selection/country-selection.component';
import { CountrySelectedGuard } from './services/country-selected-guard/country-selected.guard';
import { DeviceRegisteredGuard } from './services/device-registered-guard/device-registered.guard';
import { PatientHasDeviceGuard } from './services/patient-has-device-guard/patient-has-device.guard';
import { DeviceRegistrationComponent } from './components/device-registration/device-registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NotAuthenticatedGuard } from './services/not-authenticated-guard/not-authenticated.guard';
import { AcceptPoliciesComponent } from './components/accept-policies/accept-policies.component';
import { AppOnlyCountryComponent } from './components/country-selection/app-only-country/app-only-country.component';
import { MetadataGuard } from './services/metadata-guard/metadata.guard';
import { PoliciesGuard } from './services/policies-guard/policies.guard';
import { EnvironmentGuard } from './services/environment-guard/environment.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [ MetadataGuard ], // Execute this before any other guards to start loading metadata
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [HomeGuard, CountrySelectedGuard, EnvironmentGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'account-registration',
        loadChildren: () =>
          import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
        canActivateChild: [CountrySelectedGuard, EnvironmentGuard],
        canLoad: [CountrySelectedGuard, EnvironmentGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'support',
        loadChildren: () => import('./modules/support/support.module').then((m) => m.SupportModule),
        canActivateChild: [CountrySelectedGuard, EnvironmentGuard],
        canLoad: [CountrySelectedGuard, EnvironmentGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'country-selection',
        component: CountrySelectionComponent,
        data: { layout: 'country-selection' },
        canActivate: [NotAuthenticatedGuard],
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivateChild: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        canLoad: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
      },
      {
        path: 'history',
        loadChildren: () => import('./modules/history/history.module').then((m) => m.HistoryModule),
        canActivateChild: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        canLoad: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'library',
        loadChildren: () =>
          import('./modules/coaching-library/coaching-library.module').then(
            (m) => m.CoachingLibraryModule,
          ),
        canActivateChild: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        canLoad: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'coaching-history',
        loadChildren: () =>
          import('./modules/coaching-history/coaching-history.module').then(
            (m) => m.CoachingHistoryModule,
          ),
        canActivateChild: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        canLoad: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'my-account',
        loadChildren: () =>
          import('./modules/my-account/my-account.module').then((m) => m.MyAccountModule),
        canActivateChild: [CountrySelectedGuard, AuthGuard],
        canLoad: [CountrySelectedGuard, AuthGuard],
      },
      {
        path: 'register-device',
        component: DeviceRegistrationComponent,
        canActivate: [CountrySelectedGuard, AuthGuard, PatientHasDeviceGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [CountrySelectedGuard, EnvironmentGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: PoliciesRoutePaths.root,
        loadChildren: () => import('./modules/policies/policies.module').then((m) => m.PoliciesModule),
        canActivateChild: [PoliciesGuard, EnvironmentGuard],
        canLoad: [PoliciesGuard, EnvironmentGuard],
        data: { skipMaskMetadata: true }
      },
      {
        path: 'accept-policies',
        data: { layout: 'accept-policies' },
        component: AcceptPoliciesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'accept-policies/policies',
        loadChildren: () => import('./modules/policies/policies.module').then((m) => m.PoliciesModule),
        data: { layout: 'accept-policies/policies', skipMaskMetadata: true },
        canActivateChild: [CountrySelectedGuard],
        canLoad: [CountrySelectedGuard],
      },
      {
        path: 'myair-asia',
        component: AppOnlyCountryComponent,
        data: { layout: 'only-app-country' },
        canActivate: [NotAuthenticatedGuard, PoliciesGuard, MetadataGuard, EnvironmentGuard],
      },
      {
        path: 'myair-emea',
        component: AppOnlyCountryComponent,
        data: { layout: 'only-app-country' },
        canActivate: [NotAuthenticatedGuard, PoliciesGuard, MetadataGuard, EnvironmentGuard],
      },
      { path: '**', redirectTo: '/', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
