import { Component, ViewChild, ElementRef, Inject, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from 'src/app/services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { LanguageId } from 'src/app/types/language.type';
import { BaseComponent } from '../../base/component/base-component';
import * as DOMPurify from 'dompurify';

@Component({
  selector: 'mpp-welcome-video',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss'],
})
export class WelcomeVideoComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private languageService: LanguageService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }
  @ViewChild('videoContent') videoContent: ElementRef;
  dataState = 'is-disabled';
  videoUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.languageService.selectedLanguageId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((language: LanguageId) => this.setVideoUrl(language));
  }

  playVideo(): void {
    this.dataState = 'is-active';
    this.document.body.classList.add('no-scroll--video');
    this.sendMessageToPlayback('playVideo');
  }
  stopVideo(): void {
    this.dataState = 'is-disabled';
    this.document.body.classList.remove('no-scroll--video');
    this.sendMessageToPlayback('pauseVideo');
  }

  sendMessageToPlayback(message: string): void {
    const iframeTag = this.videoContent.nativeElement as HTMLIFrameElement;
    if (typeof iframeTag !== typeof undefined && iframeTag !== null) {
      const win = iframeTag.contentWindow;
      const frmSrc = 'https://players.brightcove.net';
      win.postMessage(message, frmSrc);
    }
  }

  setVideoUrl(languageCode: LanguageId): void {
    const id =
      {
        EnglishUk: '5767444222001',
        French: '5792096477001',
        German: '4122376555001',
      }[languageCode] || '4057741595001'; // default video id

    const unsanitizedUrl = `https://players.brightcove.net/2750693505001/NyrO8Y2ie_default/index.html?videoId=${id}&wmode=transparent&autoStart=false`;
    const sanitizedUrl = DOMPurify.sanitize(unsanitizedUrl);
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
  }
}
