
<div *ngIf="isError || !isEditMode" class="c-acc-panel c-acc-panel--read">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label u-1/2">
      <label id="deletemyAccount">{{ 'MY-ACCOUNT/MY-PRIVACY.DeleteAccount.DeleteMyAccount' | translate}}</label>
    </div>
    <div class="c-acc-panel__btn u-1/2">
      <a class="c-link-ui is-delete"
        (click)="openEdit()">{{ 'MY-ACCOUNT/MY-PRIVACY.DeleteAccount.Delete' | translate}}</a>
    </div>
  </div>
</div>

<div *ngIf="isEditMode" class="js-acc-panel is-active">
  <div class="c-acc-panel c-acc-panel--edit">
    <h2 class="c-heading-acc u-margin-bottom">{{ 'MY-ACCOUNT/MY-PRIVACY.DeleteAccount.DeleteMyAccount' | translate}}</h2>
    <p>{{'MY-ACCOUNT/MY-PRIVACY.DeleteAccount.YourAccountWillBeDeleted' | translate}}</p>
    <div class="c-form-buttons  [ o-layout o-layout--small o-layout--auto ]">
      <div class="o-layout__item">
        <button (click)="openConfirmDeletion()" type="button" class="c-btn c-btn--primary c-btn--tight"
          data-title="Delete Account">{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.DeleteAccount' | translate}}</button>
      </div>
      <div class="o-layout__item">
        <a class="c-btn c-btn--tertiary c-btn--tight" (click)="cancelEdit()">{{'MY-ACCOUNT/MY-PRIVACY.Cancel' | translate}}</a>
      </div>
    </div>
  </div>
</div>
