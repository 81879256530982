import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from '../model/alert.model';
import { AlertService } from '../services/alert.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';


@Component({ selector: 'mpp-alert', styleUrls: [ 'alert.component.scss'] ,templateUrl: 'alert.component.html' })
export class AlertComponent implements OnInit, OnDestroy {
  alerts: Alert[] = [];
  alertSubscription: Subscription;
  imageUrls: SafeResourceUrl[];
  constructor(private alertService: AlertService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.alerts$.subscribe((alerts) => {
      this.alerts = alerts;
      // Use provided image url if it exists, if not use the default exclamation mark
      this.imageUrls = [];
      this.alerts?.forEach((item: Alert) => {
        const sanitizedUrl = DOMPurify.sanitize(item.iconUrl || `${environment.endpoints.staticMyair}/Web%20Assets/images/patient_messaging/exclamation_mark.svg`);
        this.imageUrls.push(this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl));
      })
    });
    
  }

  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
  }

  onClose(alert: Alert): void {
    // eslint-disable-next-line no-param-reassign
    alert.isDismissed = true;
    if (typeof alert.onClose === 'function') {
      alert.onClose(alert);
    }
  }

  getCssClass(alert: Alert): string {
    switch (alert.type) {
      case AlertType.Info:
        return 'c-svg c-svg--warning-circle-outline-24';
      default:
        return '';
    }
  }

  getXlinkHref(alert: Alert): string {
    switch (alert.type) {
      case AlertType.Info:
        return '#c-svg--warning-circle-outline-24';
      default:
        return '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
  }

  animationend(alert: Alert, event: AnimationEvent): void {
    if (event.animationName === 'aniFadeOut') {
      this.alertService.removeAlert(alert);
    }
  }
}
