<div class="u-margin-bottom-large">

  <div class="c-acc-panel c-acc-panel--header">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__heading">
        <h2 class="c-heading-acc">{{ 'MY-ACCOUNT/MY-PRIVACY.MyPrivacy' | translate}}</h2>
      </div>
    </div>
  </div>

  <p [innerHTML]="'MY-ACCOUNT/MY-PRIVACY.PrivacyNoticeText' | translate"></p>

  <div class="c-acc-panel--group" *ngIf="showMyPrivacy">
    <div class="c-acc-panel--group__item has-all-attr">
      <mpp-share-information-view [privacySettings]="privacySettings" (stateChanged)="shareInformationStateValue = $event" *ngIf="shareInformationStateValue === myPrivacyState.View"></mpp-share-information-view>
      <mpp-share-information-edit [privacySettings]="privacySettings" (shareInformationChanged)="onShareInformationChange($event)" (stateChanged)="shareInformationStateValue = $event" *ngIf="shareInformationStateValue === myPrivacyState.Edit"></mpp-share-information-edit>
    </div>
    <ng-container *ngIf="allowAnalytics">
      <div class="c-acc-panel--group__item has-all-attr item-has-top-border">
        <mpp-allow-analytics-view *ngIf="myPrivacyStateValue === myPrivacyState.View" [privacySettings]="privacySettings" (stateChanged)="myPrivacyStateValue = $event"></mpp-allow-analytics-view>
        <mpp-allow-analytics-edit [privacySettings]="privacySettings" (analyticsChanged)="onAnalyticsChange($event)"
          (stateChanged)="myPrivacyStateValue = $event" *ngIf="myPrivacyStateValue === myPrivacyState.Edit">
        </mpp-allow-analytics-edit>
      </div>
      <div class="c-acc-panel--group__item has-all-attr item-has-top-border" *ngIf="allowAdditionalInformation">
        <mpp-receive-additional-information-view *ngIf="receiveAdditionalInfoState === myPrivacyState.View" [privacySettings]="privacySettings" (stateChanged)="receiveAdditionalInfoState = $event"></mpp-receive-additional-information-view>
        <mpp-receive-additional-information-edit *ngIf="receiveAdditionalInfoState === myPrivacyState.Edit" [privacySettings]="privacySettings" (stateChanged)="receiveAdditionalInfoState = $event" (additionalInfoChanged)="onAdditionalInformationChange($event)"></mpp-receive-additional-information-edit>
      </div>
    </ng-container>
    <div class="c-acc-panel--group__item has-all-attr item-has-top-border" *ngIf="showSmartCoachingOptOut">
      <mpp-allow-personalized-coaching-view *ngIf="allowPersonalizedStateValue === myPrivacyState.View" [privacySettings]="privacySettings" (stateChanged)="allowPersonalizedStateValue = $event"></mpp-allow-personalized-coaching-view>
      <mpp-allow-personalized-coaching-edit *ngIf="allowPersonalizedStateValue === myPrivacyState.Edit" (smartCoachingChanged)="onPersonalizedCoachingChange($event)" [privacySettings]="privacySettings" (stateChanged)="allowPersonalizedStateValue = $event"></mpp-allow-personalized-coaching-edit>  
    </div>
  </div>
  <mpp-download-my-data-view *ngIf="downloadMyDataStateValue === myPrivacyState.View" [email]="email" (stateChanged)="downloadMyDataStateValue = $event"></mpp-download-my-data-view>
  <mpp-download-my-data-edit *ngIf="downloadMyDataStateValue === myPrivacyState.Edit" [email]="email" (stateChanged)="downloadMyDataStateValue = $event"></mpp-download-my-data-edit>

</div>
