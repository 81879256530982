import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsOfUseComponent } from 'src/app/modules/policies/terms-of-use/terms-of-use.component';
import { CookieNoticeComponent } from './cookie-notice/cookie-notice.component';
import { PoliciesRoutePaths } from './policies-route-paths';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';

const routes: Routes = [
  { path: PoliciesRoutePaths.termsOfUse, component: TermsOfUseComponent },
  { path: PoliciesRoutePaths.privacyNotice, component: PrivacyNoticeComponent },
  { path: PoliciesRoutePaths.cookieNotice, component: CookieNoticeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PoliciesRoutingModule {}
