<section class="o-section">
  <div *ngIf="isApac$ | async" class="o-wrapper  o-wrapper--tablet u-text-align-center">

    <div class="o-layout">
      <div class="o-layout__item u-1/2@tablet">
        <h1 class="c-instructions">
          Download the myAir Asia mobile app as the myAir website is not available in your country.
        </h1>
      </div>
      <div class="o-layout__item u-1/2@tablet" *ngIf="isKorea">
        <h1 class="c-instructions">
          귀하의 국가에서 myAir 웹사이트를 이용할 수 없으므로 myAir Asia 모바일앱을 다운로드하시기 바랍니다.
        </h1>
      </div>
    </div>

    <div class="o-layout o-layout--auto">
      <div class="o-layout__item" *ngIf="isKorea">
        <a (click)="appStoreLinkClicked()" 
          [href]="countryService.appStoreLink$ | async" class="c-btn-app-dl">
          <img src="/assets/images/svg/apple-store-asia.svg"
          title="App Store에서 다운로드"
          target="_blank">
        </a>
      </div>
      <div class="o-layout__item" *ngIf="isKorea">
        <a (click)="playStoreLinkClicked()"
          [href]="countryService.playStoreLink$ | async" class="c-btn-app-dl">
          <img src="/assets/images/svg/google-store-asia.svg"
          title="Google Play에서 다운로드"
          target="_blank">
        </a>
      </div>
      <div class="o-layout__item" *ngIf="!isKorea">
        <a (click)="appStoreLinkClicked()"
          id="lnkAppStoreLinkApple" class="c-apple-badge"
          [style.background-image]="'url(assets/images/badges/app-store/EnglishUs.svg)'"
          [href]="countryService.appStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
      </div>
      <div class="o-layout__item" *ngIf="!isKorea">
        <a (click)="playStoreLinkClicked()" 
          id="lnkAppStoreLinkAndroid" class="c-android-badge"
          [style.background-image]="'url(assets/images/badges/play-store/EnglishUs.png)'"
          [href]="countryService.playStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
      </div>
    </div>

  </div> <!-- /isApac$ -->
  
  <!-- isLuxembourg -->
  <div *ngIf="isLuxembourg" class="o-wrapper  o-wrapper--tablet u-text-align-center">
    <div class="o-layout">

      <div class="o-layout__item u-1/2@tablet">
        <h1 class="c-instructions">
          Download the myAir by ResMed EMEA mobile app as the myAir website is not available in your country.
        </h1>
      </div>
      <div class="o-layout__item u-1/2@tablet">
        <h1 class="c-instructions">
          Téléchargez l'application mobile myAir by ResMed EMEA car le site web de myAir n'est pas disponible dans votre pays.
        </h1>
      </div>
      <div class="o-layout__item u-1/2@tablet">
        <h1 class="c-instructions">
          Laden Sie die Handy-App „myAir by ResMed EMEA“ herunter, da die myAir-Website in Ihrem Land nicht verfügbar ist.
        </h1>
      </div>
      <div class="o-layout__item u-1/2@tablet">
        <h1 class="c-instructions">
          De myAir-website is niet beschikbaar in uw land; download de mobiele app myAir by ResMed EMEA.
        </h1>
      </div>

    </div>

    <div class="o-layout o-layout--auto">
      <div class="o-layout__item">
        <a (click)="appStoreLinkClicked()" 
          id="lnkAppStoreLinkApple" class="c-apple-badge"
          [style.background-image]="'url(assets/images/badges/app-store/EnglishUs.svg)'"
          [href]="countryService.appStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
      </div>
      <div class="o-layout__item">
        <a (click)="playStoreLinkClicked()" 
          id="lnkAppStoreLinkAndroid" class="c-android-badge"
          [style.background-image]="'url(assets/images/badges/play-store/EnglishUs.png)'"
          [href]="countryService.playStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
      </div>
    </div>
  </div>
</section>