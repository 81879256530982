import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GenderId } from 'src/app/modules/registration/data/gender.type';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { genderInfos } from './types/gender-info.type';
import { LogoutCause } from './types/logout-cause.enum';
import { LoginType } from './types/login-type.enum';
import { PatientService } from '../patient/patient.service';
import { CountryService } from '../country/country.service';
import { Patient } from '../patient/types/patient.type';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private loggedInEvent = 'logged_in';
  private loggedOutEvent = 'logged_out';
  private ageGroup = 'age_group_';
  private screenDownloadMyDataEvent = 'screen_download_my_data';
  private downloadMyDataRequestedEvent = 'download_my_data_requested';
  private downloadMyDataRequestFailureEvent = 'download_my_data_request_failure';
  private screenDownloadMyDataSuccessEvent = 'screen_download_my_data_success';
  private downloadDataSuccessScreenOkEvent = 'download_data_success_screen_ok';
  private clickCreateAccount = 'click_create_account';
  private completedRegistrationForm = 'end_registration';
  private completedMaskRegistration = 'mask_setup_complete';
  private completedDeviceRegistration = 'device_setup_complete';
  private landedOnLoginScreen = 'screen_login';
  private smartCoachingEnabled = 'smart_coaching_enabled';
  private appStoreRedirect = "app_store_redirect";
  private playStoreRedirect = "play_store_redirect";

  constructor(
    private analytics: AngularFireAnalytics,
    private patientService: PatientService,
    private countryService: CountryService,
  ) {}

  public smartCoachingOn = "on";
  public smartCoachingOff = "off";
  public redirectLocationAppOnly = "app_only";
  public redirectLocationVideoCarousel = "video_carousel";
  public redirectLocationFeatureDescription = "feature_description";

  public logPlayStoreRedirect(countryCode: string, redirectLocation: string): void {
    this.analytics.logEvent(this.playStoreRedirect, {'country': countryCode, 'redirect_location': redirectLocation })
  }

  public logAppStoreRedirect(countryCode: string, redirectLocation: string): void {
    this.analytics.logEvent(this.appStoreRedirect, {'country': countryCode, 'redirect_location': redirectLocation })
  }

  public logSmartCoachingEnabled(onOrOff: string): void {
    this.analytics.logEvent(this.smartCoachingEnabled, {'on_off': onOrOff })
  }

  public logClickCreateAccountEvent(): void {
    this.analytics.logEvent(this.clickCreateAccount);
  }

  public logCompleteRegistrationFormEvent(): void {
    this.analytics.logEvent(this.completedRegistrationForm);
  }

  public logCompletedDeviceRegistrationEvent(): void {
    this.analytics.logEvent(this.completedDeviceRegistration);
  }

  public logCompletedMaskRegistrationEvent(): void {
    this.analytics.logEvent(this.completedMaskRegistration);
  }

  public logLandedOnLoginScreenEvent(): void  {
    this.analytics.logEvent(this.landedOnLoginScreen);
  }

  public setUserId(userId: string): void {
    this.analytics.setUserId(userId);
  }

  public logLoggedInEvent(): void {
    this.analytics.logEvent(this.loggedInEvent, { type: LoginType.Password });
  }

  public logLoggedOutEvent(): void {
    this.analytics.logEvent(this.loggedOutEvent, { logout_cause: LogoutCause.UserInitiated });
  }

  public logScreenDownloadMyDataEvent(): void {
    this.analytics.logEvent(this.screenDownloadMyDataEvent);
  }

  public logDownloadMyDataRequestedEvent(): void {
    this.analytics.logEvent(this.downloadMyDataRequestedEvent);
  }

  public logDownloadMyDataRequestFailureEvent(): void {
    this.analytics.logEvent(this.downloadMyDataRequestFailureEvent);
  }

  public logScreenDownloadMyDataSuccessEvent(): void {
    this.analytics.logEvent(this.screenDownloadMyDataSuccessEvent);
  }

  public logDownloadDataSuccessScreenOkEvent(): void {
    this.analytics.logEvent(this.downloadDataSuccessScreenOkEvent);
  }

  public setUserProperties(birthday: Date, gender: GenderId): void {
    this.analytics.setUserProperties({
      age_group: this.ageGroup + this.getAgeGroup(birthday),
    });
    this.analytics.setUserProperties({ gender_info: genderInfos[gender] });
  }

  private getAge(birthday: Date): number {
    const dateNow = new Date().getFullYear();
    let age = 0;
    if (dateNow > birthday.getFullYear()) {
      age = dateNow - birthday.getFullYear();
    }
    return age;
  }

  async checkAndSetAnalytics(): Promise<void> {
    const [patientData, error] = await this.patientService.getAnalyticsInfo().toPromise();

    if (error) {
      return;
    }

    if (patientData.patient.analyticsMode === AnalyticsMode.untracked) {
      await this.analytics.setAnalyticsCollectionEnabled(false);
    } else if (
      patientData.patient.analyticsMode === AnalyticsMode.identified &&
      this.countryService.getSelectedCountryId() === 'US'
    ) {
      await this.setAnalyticsParameters(patientData.patient.id, patientData.patient);
    } else {
      await this.setAnalyticsParameters(null, patientData.patient);
    }
  }

  private async setAnalyticsParameters(id: string, patient: Patient) {
    await this.analytics.setAnalyticsCollectionEnabled(true);
    this.setUserId(id);
    this.setUserProperties(patient.dateOfBirth, patient.gender);
  }

  private getAgeGroup(birthday: Date): number {
    const age = this.getAge(birthday);
    let group = 1;
    if (age < 18) {
      group = 1;
    } else if (age >= 18 && age <= 24) {
      group = 2;
    } else if (age >= 25 && age <= 34) {
      group = 3;
    } else if (age >= 35 && age <= 44) {
      group = 4;
    } else if (age >= 45 && age <= 64) {
      group = 5;
    } else {
      group = 6;
    }
    return group;
  }
}
