<!-- #region Patient Health Profile -->
<form [formGroup]="healthProfileForm">
  <div class="c-form--has-validation [ u-11/12@tablet u-7/12@desktop ]">
    <!-- #region AHI Events -->
    <div class="c-form-field">
      <label class="c-form-field__label" for="ahi-events">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.HowManyEventsDetected" | translate}}
        <small>(<span>{{"MY-ACCOUNT/MY-HEALTH-PROFILE.HelpShowProgress" | translate}}</span>)</small></label>

      <div class="c-form-field__input c-input--validation" [attr.data-form-field-status]="healthProfileForm.get('AHIevents').value === null
                                                || healthProfileForm.get('AHIevents').untouched
                                                ? 'incomplete' :
                                                healthProfileForm.get('AHIevents').touched && healthProfileForm.get('AHIevents').valid ?
                                                'completed' : 'error'">
        <input id="ahi-events" type="text" mppOnlyNumbers='float' formControlName="AHIevents" placeholder="##" maxlength="5" class="c-input c-input--number" />
      </div>

      <div
        *ngIf="healthProfileForm.get('AHIevents').touched
               && healthProfileForm.get('AHIevents').invalid">
        <div
          class="c-form-field__error"
          role="alert"
          aria-live="assertive"
          *ngIf="!!healthProfileForm.get('AHIevents').errors">
            <ng-container *ngFor="let err of getAHIeventsErrorKeys()">
              {{ err | translate }}
            </ng-container>
        </div>
      </div>

      <div class="c-form-field__hint">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.AHItip" | translate}}</div>
    </div>
    <!-- #endregion AHI Events -->

    <!-- #region Sleep Threapy Start Group -->
    <div class="c-form-field">
      <label class="c-form-field__label" for="sleep-therapy-start">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.WhenTherapyStarted" | translate}}
        <small
          class="u-font-weight-regular"><span>{{"MY-ACCOUNT/MY-HEALTH-PROFILE.Approximately" | translate}}</span></small></label>
      <div class="c-input c-input--select">
        <select id="sleep-therapy-start" formControlName="startTherapyGroup">
          <option [ngValue]="null">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.SelectAnOption" | translate}}</option>
          <option *ngFor="let startTherapyGroupId of getStartTherapyGroupIds()" [value]="startTherapyGroupId">
            {{ getStartTherapyGroupName(startTherapyGroupId) | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <!-- #endregion Sleep Threapy Start Group -->

  <!-- #region Sleep Test Type-->
  <div class="c-form--has-validation [ u-11/12@tablet u-7/12@desktop ]">
    <div class="c-form-field u-margin-bottom-large">
      <label class="c-form-field__label">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.WhereWasSleepTest" | translate}}</label>

      <div class="c-form-field__input c-input--validation" [attr.data-form-field-status]="healthProfileForm.get('sleepTestType').value === notKnownSleepTestTypeKey
                                                ? 'incomplete' : 'completed'">
        <div class="o-layout o-layout--auto o-layout--tiny">
          <div class="o-layout__item u-1/3" *ngFor="let sleepTest of getSleepTestTypeIds()">
            <div class="c-form-img-picker c-form-img-picker--mask-type">
              <a  [class]="healthProfileForm.get('sleepTestType').value === sleepTest ?
                        'c-form-img-picker__item c-form-img-picker__item--round is-active'
                        : 'c-form-img-picker__item c-form-img-picker__item--round'">
                <input [id]="'sleep-test-' + getSleepTestTypeTranslationId(sleepTest) | translate" type="image" class="c-form-img-picker__img" [src]="getSleepTestImgSource(sleepTest)"
                  [alt]="getSleepTestTypeTranslationId(sleepTest) | translate" formControlName="sleepTestType"
                  [value]="sleepTest" (click)="onSleepTestTypeSelected(sleepTest); $event.preventDefault()" />
              </a>
              <label
                class="c-form-img-picker__label" [for]="'sleep-test-' + getSleepTestTypeTranslationId(sleepTest) | translate"><span>{{getSleepTestTypeTranslationId(sleepTest) | translate }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion Sleep Test Type-->

  <!-- implementation postponed due to MWEB-714 -->
  <!-- <div class="c-form--has-validation [ u-11/12@tablet u-7/12@desktop ]" data-form-field="signup-howSleepy" data-form-field-status="incomplete">
    <fieldset class="c-form-field u-margin-bottom-none" data-validation="default">
      <legend class="c-form-field__label u-margin-bottom" id="baselineSleepiness">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.PriorToTherapy" | translate}}</legend>
      <div class="c-form-field__input">
        <ul class="o-inline-group" aria-labelledby="baselineSleepiness" role="radiogroup">

          <li class="c-input c-input--img-radio [ u-1/2@mobile u-1/5@tablet ]"
            *ngFor="let item of baselineSleepinessList"
            [class.is-active]="healthProfileForm.get('baselineSleepiness').value === baselineSleepinessValues[item]">
            <input
              [id]="baselineSleepinessValues[item]"
              type="radio"
              [value]="baselineSleepinessValues[item]"
              formControlName="baselineSleepiness">
            <label [for]="baselineSleepinessValues[item]">
              <svg class="c-svg c-svg--sleepy-' + item + ' c-svg--flush">
                <use [attr.xlink:href]="'#c-svg--sleepy-' + item + '-1024'"></use>
              </svg>
            </label>
          </li>

        </ul>
      </div>
    </fieldset>
  </div> -->

</form>
  <!-- #endregion Patient Health Profile -->
