<div class="c-acc-panel c-acc-panel--header">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__heading">
      <h2 id="mynotifications" class="c-heading-acc">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.MyNotifications' | translate}}</h2>
    </div>

    <div class="c-acc-panel__btn">
      <a class="c-link-ui" (click)="edit()">{{ 'MY-ACCOUNT.Change' | translate}}</a>
    </div>
  </div>
</div>

<div class="c-acc-panel c-acc-panel--stacked">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.CoachingTips' | translate}}</span>
    </div>
    <div class="c-acc-panel__value">
      <span
        *ngIf="!notificationPreferences.coachingEmailEnabled">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Off' | translate}}</span>
      <span *ngIf="notificationPreferences.coachingEmailEnabled">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Email' | translate}}</span>
    </div>
  </div>
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.CleaningAndInspectionReminders' | translate}}</span>
    </div>
    <div class="c-acc-panel__value">
      <span
        *ngIf="!notificationPreferences.cleaningEmailEnabled">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Off' | translate}}</span>
      <span *ngIf="notificationPreferences.cleaningEmailEnabled">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Email' | translate}}</span>
    </div>
  </div>
</div>
