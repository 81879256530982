import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { ResponseResult } from 'src/app/services/showtime-api/response.structure';
import { ShowTimeApiService } from 'src/app/services/showtime-api/showtime-api.service';
import { environment } from 'src/environments/environment';

export enum DialogType {
  None,
  TermsOfUseDialog,
  PrivacyNoticeDialog,
  CookieNoticeDialog,
}

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends ShowTimeApiService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected messageService: AlertService,
  ) {
    super(messageService);
  }
  currentDialogType: BehaviorSubject<DialogType> = new BehaviorSubject(DialogType.None);

  openTermsOfUsePolicy(): void {
    this.router.navigate(['policies/terms-of-use']);
  }

  openPrivacyNoticePolicy(): void {
    this.router.navigate(['policies/privacy-notice']);
  }

  openCookieNoticePolicy(): void {
    this.router.navigate(['policies/cookie-notice']);
  }

  acceptPolicies(): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${environment.endpoints.apiMyAir}/V1/Patient/AcceptPolicies`, {})
      .pipe(this.showError());
  }
}
