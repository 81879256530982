import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryService } from '../../../services/country/country.service';
import { LanguageService } from '../../../services/language/language.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyHttpService {
  constructor(
    private http: HttpClient,
    private countryService: CountryService,
    private languageService: LanguageService,
  ) {}

  getTermsOfUseHtml(): Observable<string> {
    const params = new HttpParams()
      .set('countryId', this.countryService.selectedCountryId || 'US')
      .set('locale', this.languageService.getCurrent2LettersLanguage());
    return this.http.get(`${environment.endpoints.staticMyair}/policies/termsOfUse.html`, {
      params,
      responseType: 'text',
    });
  }

  getPrivacyNoticeHtml(): Observable<string> {
    const params = new HttpParams()
      .set('countryId', this.countryService.selectedCountryId || 'US')
      .set('locale', this.languageService.getCurrent2LettersLanguage());
    return this.http.get(`${environment.endpoints.staticMyair}/policies/privacyNotice.html`, {
      params,
      responseType: 'text',
    });
  }

  getCookieNoticeHtml(): Observable<string> {
    const params = new HttpParams()
      .set('countryId', this.countryService.selectedCountryId || 'US')
      .set('locale', this.languageService.getCurrent2LettersLanguage());
    return this.http.get(`${environment.endpoints.staticMyair}/policies/cookieNotice.html`, {
      params,
      responseType: 'text',
    });
  }
}
