<section id="section1" class="o-section">
  <div class="o-wrapper">

    <div class="o-layout">
      <div class="o-layout__item  u-10/12@desktop u-push-1/12@desktop">

        <h1 class="c-heading-page">{{ 'MY-ACCOUNT/MY-EQUIPMENT.MyEquipment' | translate }}</h1>

        <mpp-add-device [deviceFormGroup]="deviceFormGroup"></mpp-add-device>

        <div *ngIf="isEU" class="[ u-11/12@tablet u-7/12@desktop ]">
          <div class="c-form-field">
            <div class="c-form-field__input">
              <div class="c-input c-input--checkbox align-top">
                <input [formControl]="legallyAuthorized" type="checkbox" id="legally-authorized">
                <label class="c-form-field__label" for="legally-authorized">{{ 'MY-ACCOUNT/MY-EQUIPMENT.LegallyAuthorizedAgree' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="c-form-buttons  [ o-layout o-layout--auto ]">
          <div class="o-layout__item">
            <button class="c-btn c-btn--primary"
              [disabled]="deviceFormGroup.invalid || legallyAuthorized.invalid" (click)="updatePatientDevice()"><span>{{ 'MY-ACCOUNT/MY-EQUIPMENT.Save' | translate }}</span>
            </button>
          </div>
          <div class="o-layout__item">
            <a class="c-btn c-btn--tertiary" (click)="cancelDeviceChanges()">{{ 'MY-ACCOUNT/MY-EQUIPMENT.Cancel' | translate }}</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
