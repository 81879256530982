import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { first, map,  tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { WINDOW_OBJECT } from 'src/app/services/window/window.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../storage/storage.service';
import { MetadataService } from '../metadata/metadata.service';
import { CountryData, CountryId } from 'src/app/types/country.type';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends ShowTimeApiService {
  private CountryIdKey = 'CurrentMyAirCountryId';
  selectedCountryId$ = new BehaviorSubject<CountryId>('US');
  selectedCountryId: CountryId;
  env = environment;
  public countries$ = new BehaviorSubject({});
  public playStoreLink$ = new BehaviorSubject<string>(null);
  public appStoreLink$ = new BehaviorSubject<string>(null);
  public appId$: Observable<string>

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private metadataService: MetadataService,
    @Inject(WINDOW_OBJECT) public window: Window,
    protected messageService: AlertService,
  ) {
    super(messageService);
  }

  initializeMobileStoreLinks() {
    this.obtainPlayStoreLink().subscribe(link => {
      this.playStoreLink$.next(link);
    });
    this.obtainAppStoreLink().subscribe(link => {
      this.appStoreLink$.next(link);
    })
  }

  // returns all country objects and their properties
  loadCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('countries').pipe(
      tap(data => {
        this.countries$.next(data);
      })
    );
  }

  // returns an array of only those countries which have full myAir Web support (as opposed to app-only countries)
  loadSupportedCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('SUPPORTED_COUNTRIES');
  }

  // returns an array of app-only countries (no myAir Web support)
  loadAppOnlyCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('appOnlyCountries');
  }

  loadUS_AU_NZCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('US_AU_NZ_COUNTRIES');
  }

  loadEUCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('EU_COUNTRIES');
  }

  loadAPACCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('APAC_COUNTRIES');
  }

  loadLATAMCountries(): Observable<any> {
    return this.metadataService.getMetadataFromLocal('LATAM_COUNTRIES');
  }

  selectCountry(countryId: CountryId): void {
    this.setSelectedCountryId(countryId);
  }

  latAmNonRollout(): boolean {
    return ['AR', 'CL', 'CO', 'MX'].indexOf(this.getSelectedCountryId()) !== -1;
  }

  obtainPlayStoreLink(): Observable<string> {
    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries(),
      this.metadataService.getMetadataFromApi()
    ]).pipe(
      first(),
      map(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES, metadata]) => {
        if (US_AU_NZCountries.includes(this.selectedCountryId)) {
          return metadata.products['myAir'].googlePlayUrl;
        } else if (LATAM_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir LATAM'].googlePlayUrl;
        } else if (this.selectedCountryId === 'CA') {
          return metadata.products['myAir Canada'].googlePlayUrl;
        } else if (EU_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir EU'].googlePlayUrl;
        } else if (APAC_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir APAC'].googlePlayUrl;
        } else {
          // We should never get here. It means a country was added or collapsed improperly
          throw new Error('Invalid Country');
        }
      })
    );
  }

  obtainAppStoreLink(): Observable<string> {
    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries(),
      this.metadataService.getMetadataFromApi()
    ]).pipe(
      first(),
      map(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES, metadata]) => {
        if (US_AU_NZCountries.includes(this.selectedCountryId)) {
          return (metadata.products['myAir'].appStoreUrl as string).replace('{selectedCountryId}', this.selectedCountryId);
        } else if (LATAM_COUNTRIES.includes(this.selectedCountryId)) {
          return (metadata.products['myAir LATAM'].appStoreUrl as string).replace('{selectedCountryId}', this.selectedCountryId);
        } else if (this.selectedCountryId === 'CA') {
          return (metadata.products['myAir Canada'].appStoreUrl as string).replace('{selectedCountryId}', this.selectedCountryId);
        } else if (EU_COUNTRIES.includes(this.selectedCountryId)) {
          return (metadata.products['myAir EU'].appStoreUrl as string).replace('{selectedCountryId}', this.selectedCountryId);
        } else if (APAC_COUNTRIES.includes(this.selectedCountryId)) {
          return (metadata.products['myAir APAC'].appStoreUrl as string).replace('{selectedCountryId}', this.selectedCountryId);
        } else {
          // We should never get here. It means a country was added or collapsed  improperly
          throw new Error('Invalid Country');
        }
      })
    );
  }

  // For use with iOS Smart App Banner
  obtainAppId(): Observable<string> {
    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries(),
      this.metadataService.getMetadataFromApi()
    ]).pipe(
      first(),
      map(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES, metadata]) => {
        if (US_AU_NZCountries.includes(this.selectedCountryId)) {
          return metadata.products['myAir'].appStoreId;
        } else if (LATAM_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir LATAM'].appStoreId;
        } else if (this.selectedCountryId === 'CA') {
          return metadata.products['myAir Canada'].appStoreId;
        } else if (EU_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir EU'].appStoreId;
        } else if (APAC_COUNTRIES.includes(this.selectedCountryId)) {
          return metadata.products['myAir APAC'].appStoreId;
        } else {
          // We should never get here. It means a country was added or collapsed improperly
          throw new Error('Invalid Country');
        }
      })
    );
  }

  // only called from MainComponent, AFTER loadCountries() completes
  checkAndSetCountryId(): void {
    const countries = this.countries$.getValue();
    const currentMyAirCountryId = this.localStorage.getItem(this.CountryIdKey) as CountryId;
    if (currentMyAirCountryId && countries[currentMyAirCountryId]) {
      this.selectedCountryId = currentMyAirCountryId;
      this.selectedCountryId$.next(currentMyAirCountryId);
    }
  }

  setSelectedCountryId(countryId: CountryId): void {
    this.selectedCountryId = countryId;
    this.localStorage.setItem(this.CountryIdKey, countryId);
    this.selectedCountryId$.next(countryId);
  }

  getSelectedCountryId(): CountryId {
    return this.localStorage.getItem(this.CountryIdKey) as CountryId;
  }

  getSelectedCountry(): CountryData {
    const countries = this.countries$.getValue();
    return countries[this.getSelectedCountryId()];
  }

  changeCountry(countryId: CountryId, route: string): void {
    this.selectCountry(countryId);
    this.regionRedirect(countryId, route);
  }

  private regionRedirect(countryId: CountryId, route: string): void {
    const countries = this.countries$.getValue();
    const isEuropeRegion = countries[countryId].region === "EU";
    // Obtain potential redirect from local storage
    const potentialRedirectUrl = this.localStorage.getItem(this.localStorage.redirectURL);
    // Override the route if a redirect exists in local storage
    route = potentialRedirectUrl ? potentialRedirectUrl : route;
    // Clear local storage to prevent future redirects
    this.localStorage.removeItem(this.localStorage.redirectURL);

    if (this.env.isEU && !isEuropeRegion) {
      this.window.location.href = `${this.env.myAirLink.NonEU}${route}?redirectCountry=${countryId}`;
    } else if (!this.env.isEU && isEuropeRegion) {
      this.window.location.href = `${this.env.myAirLink.EU}${route}?redirectCountry=${countryId}`;
    } else {
      this.router.navigate([route]);
    }
  }
}