import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
 providedIn: 'root'
})
export class MetadataGuard {
  constructor(private metadataService: MetadataService) { }

  // This calls metadata from our local web-config.json file
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
    this.metadataService.checkIfMetadataLocalLoadNecessary();
    return true;
  }

  // This calls metadata from our backend. In a future refactor, consider moving this to a metadataApiGuard
  // do not use this canActivate on the /country-selection route
  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Perform the check to ensure API metadata is loaded
    this.metadataService.checkIfMetadataApiLoadNecessary();
    return true;
  }

}